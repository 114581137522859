import React from 'react';
import importedStyles from "./Privacy.module.sass";
import Navbar from "../../components/Navbar/Navbar";

class Privacy extends React.Component {

    state = {};

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Navbar path={window.location.pathname} hidden={this.state.hidden} className={importedStyles.navBar} style={{backgroundColor: '#4FA9B3'}}/>
                <div className={importedStyles.privacy}>
                    Por favor, lee con atención<br/>
                    No dudes en consultarnos cualquier duda
                    <hr/>
                    {/* PRIVACIDAD */}
                    <hr/>
                    <h1>PRIVACIDAD</h1>
                    <h2>OBJETO</h2>
                    PulsID y la web: latam.mipulsid.com se rigen por la Ley Orgánica 3/2018 de Protección de Datos Personales y Garantía de los Derechos Digitales.
                    Por tanto, sus datos serán tratados según esa normativa y no la correspondiente a Argentina: Ley 25326, sancionada el 4 de octubre de 2000
                    El presente aviso legal regula el uso y utilización del sitio web www.mipulsid.com, del que es
                    titular SOCIEDAD EUROPEA DE VERIFICACIÓN DE IDENTIDAD S.L. (en adelante, EL PROPIETARIO DE LA
                    WEB). La navegación por el sitio web de EL PROPIETARIO DE LA WEB le atribuye la condición de
                    USUARIO del mismo y conlleva su aceptación plena y sin reservas de todas y cada una de las
                    condiciones publicadas en este aviso legal, advirtiendo de que dichas condiciones podrán ser
                    modificadas sin notificación previa por parte de EL PROPIETARIO DE LA WEB, en cuyo caso se
                    procederá a su publicación y aviso con la máxima antelación posible. Por ello es recomendable
                    leer atentamente su contenido en caso de desear acceder y hacer uso de la información y de los
                    servicios ofrecidos desde este sitio web. El usuario además, se obliga a hacer un uso correcto
                    del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico
                    y el presente Aviso Legal, y responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros,
                    de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de
                    dicha obligación. Cualquier utilización distinta a la autorizada está expresamente prohibida,
                    pudiendo EL PROPIETARIO DE LA WEB denegar o retirar el acceso y su uso en cualquier momento.

                    <h2>IDENTIFICACIÓN</h2>
                    EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la
                    Sociedad de la Información y de Comercio Electrónico, le informa de que:
                    Su denominación social es: SOCIEDAD EUROPEA DE VERIFICACIÓN DE IDENTIDAD S.L.
                    Su CIF es: BB44503985
                    Su domicilio social está en: C\Santa Caterina 21, 2º 3, 12540, Vila-Real, Castellón
                    Registro Mercantil de Castellón, el 26/03/18, en el Tomo 1756 , Folio 86, Inscripción 1, Hoja
                    CS-40883


                    <h2>COMUNICACIONES</h2>
                    Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que
                    detallamos a continuación: Todas las notificaciones y comunicaciones entre los usuarios y
                    PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos, cuando se realicen a través
                    de cualquier medio de los detallados anteriormente.

                    <h2>CONDICIONES DE ACCESO Y UTILIZACIÓN</h2>
                    El sitio web y sus servicios son de acceso libre y gratuito. No obstante, PROPIETARIO DE LA WEB
                    puede condicionar la utilización de algunos de los servicios ofrecidos en su web a la previa
                    cumplimentación del correspondiente formulario. El usuario garantiza la autenticidad y
                    actualidad de todos aquellos datos que comunique a PROPIETARIO DE LA WEB y será el único
                    responsable de las manifestaciones falsas o inexactas que realice. El usuario se compromete
                    expresamente a hacer un uso adecuado de los contenidos y servicios de PROPIETARIO DE LA WEB y a
                    no emplearlos para, entre otros:
                    Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de
                    apología del terrorismo o, en general, contrarios a la ley o al orden público.
                    Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar,
                    estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o
                    sistemas físicos y lógicos de PROPIETARIO DE LA WEB o de terceras personas; así como
                    obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo
                    masivo de los recursos informáticos a través de los cuales PROPIETARIO DE LA WEB presta sus
                    servicios.
                    Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de
                    los sistemas informáticos de PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer
                    información. Vulnerar los derechos de propiedad intelectual o industrial, así como violar la
                    confidencialidad de la información de PROPIETARIO DE LA WEB o de terceros.
                    Suplantar la identidad de cualquier otro usuario.
                    Reproducir, copiar, distribuir, poner a disposición de, o cualquier otra forma de comunicación
                    pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del
                    titular de los correspondientes derechos o ello resulte legalmente permitido.
                    Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y
                    comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa
                    solicitud o consentimiento.

                    Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos,
                    tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya
                    propiedad pertenece a PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos al usuario
                    ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario
                    para el correcto uso de la web. En definitiva, los usuarios que accedan a este sitio web pueden
                    visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los
                    elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores
                    conectados a redes, ni sean objeto de ningún tipo de explotación. Asimismo, todas las marcas,
                    nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son
                    propiedad de PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al mismo
                    atribuya al usuario derecho alguno sobre los mismos. La distribución, modificación, cesión o
                    comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente
                    autorizado por el titular de los derechos de explotación quedan prohibidos. El establecimiento
                    de un hiperenlace no implica en ningún caso la existencia de relaciones entre PROPIETARIO DE LA
                    WEB y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por
                    parte de PROPIETARIO DE LA WEB de sus contenidos o servicios. PROPIETARIO DE LA WEB no se
                    responsabiliza del uso que cada usuario le dé a los materiales puestos a disposición en este
                    sitio web ni de las actuaciones que realice en base a los mismos.

                    <h2>EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA UTILIZACIÓN</h2>
                    El contenido del presente sitio web es de carácter general y tiene una finalidad meramente
                    informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su
                    exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo
                    específico. PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento jurídico,
                    cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:
                    La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o
                    actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los
                    contenidos transmitidos, difundidos, almacenados, puestos a disposición, a los que se haya
                    accedido a través del sitio web o de los servicios que se ofrecen.
                    La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en
                    los sistemas informáticos, documentos electrónicos o datos de los usuarios.
                    El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el
                    presente aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo
                    ejemplificativo, PROPIETARIO DE LA WEB no se hace responsable de las actuaciones de terceros que
                    vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al
                    honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia
                    de competencia desleal y publicidad ilícita.
                    Asimismo, PROPIETARIO DE LA WEB declina cualquier responsabilidad respecto a la información que
                    se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de
                    los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la
                    existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web.
                    PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del funcionamiento o accesibilidad de
                    los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco
                    será responsable del resultado obtenido. PROPIETARIO DE LA WEB no se responsabiliza del
                    establecimiento de hipervínculos por parte de terceros.

                    <h2>PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO</h2>
                    En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias
                    que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización
                    de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá
                    enviar una notificación a PROPIETARIO DE LA WEB identificándose debidamente y especificando las
                    supuestas infracciones.

                    <h2>PUBLICACIONES</h2>
                    La información administrativa facilitada a través del sitio web no sustituye la publicidad legal
                    de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados
                    formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único
                    instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio
                    web debe entenderse como una guía sin propósito de validez legal.

                    <h2>LEGISLACIÓN APLICABLE</h2>
                    Las condiciones presentes se regirán por la legislación española vigente. La lengua utilizada
                    será el Castellano.


                    <h2>CLÁUSULA PARA EL CORREO ELECTRÓNICO</h2>
                    Información Básica de Protección de Datos. Responsable: SOCIEDAD EUROPEA DE VERIFICACIÓN DE
                    IDENTIDAD S.L.; Finalidad: prestarle los servicios que nos ha solicitado, atender sus
                    solicitudes de información y enviarle comunicaciones comerciales; Legitimación: Ejecución de
                    contrato, Interés legítimo del responsable o Consentimiento del Interesado. Cesiones: No se
                    cederán sus datos a terceros salvo obligación legal. Derechos: Tiene derecho a acceder,
                    rectificar y suprimir los datos, así como otros derechos, indicados en la información adicional,
                    que puede ejercer en: rgpd@sevisl.es. Información adicional: Puede consultar información
                    adicional y detallada sobre Protección de Datos en: www.sevisl.es/privacidad Este mensaje y sus
                    archivos adjuntos van dirigidos exclusivamente a su destinatario, pudiendo contener información
                    confidencial sometida a secreto profesional. No está permitida su reproducción o distribución
                    sin nuestra autorización expresa. Si usted no es el destinatario final por favor elimínelo e
                    infórmenos por esta vía.
                    <br/>
                    <br/>
                    © Sociedad Europea de Verificación S.L. 2018. All Rights Reserved.


                </div>
            </div>
        );
    }
}

export default Privacy;