import React from 'react';
import importedStyles from "./Landing.module.sass";
import Carousel from 'nuka-carousel';
import history from '../../helpers/history';
import NextArrowIcon from '@material-ui/icons/NavigateNext';
import BeforeArrowIcon from '@material-ui/icons/NavigateBefore';
import TapIcon from '@material-ui/icons/TouchApp';
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';
import CloseIcon from '@material-ui/icons/Close';
import BulletIcon from '@material-ui/icons/ChevronRight';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarEmptyIcon from '@material-ui/icons/StarBorder';
import Snack from "../../components/Snack/Snack";
import BuyModals from "../Modals/BuyModals";
import LoginModal from "../Modals/LoginModal/LoginModal";
import ReactGA from 'react-ga';
import Navbar from "../../components/Navbar/Navbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import * as qs from 'query-string';
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography/Typography";
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import {accept_cookies, show_buy_modal} from "../Modals/ModalsActions";
import IconButton from "@material-ui/core/IconButton";
import Image from 'react-image-webp';
import {Helmet} from "react-helmet";
import {Grid, ListItem, ListItemIcon} from "@material-ui/core";
import BeforeCloseAlertModal from "./components/BeforeCloseAlertModal/BeforeCloseAlertModal";
import NewsletterModal from "./components/NewsletterModal/NewsletterModal";
import Cookies from "universal-cookie";
import PresaleModal from "./components/PresaleModal/PresaleModal";
import ReactPixel from "react-facebook-pixel";
import NewsletterSection from "./components/NewsletterSection/NewsletterSection";
import TidioChat from "../../components/TidioChat/TidioChat";
import Countdown from "react-countdown-now";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import 'video.js/dist/video-js.css';
import CountUp from "react-countup";
import HeadBanner from "../../components/HeadBanner/HeadBanner";
import moment from "moment";
import GoogleIcon from "../../img/icons/googleLogo.svg";
import FacebookLogo from "../../img/icons/facebookLogo.png";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

const cookies = new Cookies();
const mobile = window.matchMedia("(max-width: 700px)");

class Landing extends React.Component {


    state = {
        openLoginModal: false,
        paymentSuccessAndShareModal: false,
        modalInfoKids: false,
        modalInfoOld: false,
        modalInfoMedicine: false,
        modalInfoDiversity: false,
        mocksModal: false,
        mocksModal2: false,
        with: window.outerWidth,
        height: window.innerHeight + 175,
        fabHidden: true,
        openBeforeCloseAlertModal: false,
        beforeCloseAlertModalShowed: false,
        openNewsletterModal: false,
        requestedDocument: '',
        openPresaleModal: false,
        openOfferSnack: true,
        promoCode: null,
        showHistory: false,
        showWhat: false,
        newBracelet: false,
        currentBannerClaim: 3,
    };

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleBuyModal = this.handleBuyModal.bind(this);
    }

    componentDidMount() {
        const params = qs.parse(this.props.location.search);
        ReactPixel.pageView();
        ReactGA.pageview('/landing');

        /*Open buy modal if param shop true*/
        if (params.shop) {
            window.location.replace("https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3");

            this.props.dispatch(show_buy_modal(true));
            if (params.promoCode) {
                this.setState({promoCode: params.promoCode});
            }
        } else {
            this.props.dispatch(show_buy_modal(false)); //hide previous modal by default
        }

        if (typeof params.paymentSuccess !== 'undefined') {
            if (params.paymentSuccess === 'false') {
                this.props.dispatch(showSnack('Vaya, parece que ha ocurrido algún error en el proceso de pago', snackTypes.error));
            } else {
                ReactGA.modalview('/BraceletPayed');
                this.setState({paymentSuccessAndShareModal: true});
                //this.props.dispatch(showSnack('Pedido realizado con éxito, le hemos mandado la información necesaria a su correo electronico', snackTypes.success));
            }
        }
        //Add listener to control fab visibility based on scroll
        window.addEventListener('scroll', this.handleFabButtonVisibility);

        // Add listener to when user tries to get out of page
        document.getElementsByTagName('html')[0].addEventListener("mouseleave", (e) => this.handleWindowLeave(e));

        if (this.props.user && this.props.user.uuid) {
            this.setState({newBracelet: true});
        }

        this.animateCSS('.primaryCTA', 'heartBeat');
        setInterval(() => {
            let animations = ['shake', 'wobble', 'tada', 'heartBeat', 'bounce', 'rubberBand', 'jello'];
            let randomNumber = Math.floor(Math.random() * animations.length);
            this.animateCSS('.primaryCTA', animations[randomNumber]);
        }, 4000)

    }

    componentWillUnmount() {
        // If this component is unmounted, stop listening
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.handleFabButtonVisibility);
        document.getElementsByTagName('html')[0].removeEventListener("mouseout", (e) => this.handleWindowLeave(e));

    }

    animateCSS(element, animationName, callback) {
        const node = document.querySelector(element);
        if (node) {
            node.classList.add('animated', animationName);

            function handleAnimationEnd() {
                node.classList.remove('animated', animationName);
                node.removeEventListener('animationend', handleAnimationEnd);

                if (typeof callback === 'function') callback()
            }

            node.addEventListener('animationend', handleAnimationEnd)
        }

    }


    checkNewsletterSubscription = () => {
        let subscriptionCookie = cookies.get('subscribedToNewsletter');
        return !!subscriptionCookie;
    };

    initTabTitleChanger = () => {
        let title = document.title;
        let audio = new Audio("./ding.mp3");
        let messages = ["Nuevo mensaje (1)", "Tienes 1 mensaje", "1 Nueva notificación"];
        let messsagesRandom = messages[Math.floor(Math.random() * messages.length)];

        window.onblur = () => {
            setTimeout(message, 3000);
            setTimeout(playSound, 3000);
        };

        function playSound() {
            audio.play();
        }

        function message() {
            document.title = messsagesRandom;
        }

        window.onfocus = function () {
            document.title = title;
        }

    };


//////////// HANDLERS ////////////////

    handleWindowLeave = (event) => {

        /*
        let alreadySubscribed = this.checkNewsletterSubscription();
        - Only trigger modal when have this conditions:
            # Leaving through top of browser inner window
            # This modal is not already showed
            # The user is not already subscribed
            # The newsletter modal is not opened
            # The presale modal is not opened

        if (event.clientY <= 0 && !this.state.beforeCloseAlertModalShowed && !this.state.openNewsletterModal && !alreadySubscribed && !this.state.openPresaleModal) {
            this.setState({openBeforeCloseAlertModal: true, beforeCloseAlertModalShowed: true});
        }
        */
    };

    closeAlertModal = () => {
        this.setState({openBeforeCloseAlertModal: false});
    };
    openAlertModal = () => {
        this.setState({openBeforeCloseAlertModal: true});
    };
    openInfoModalKids = () => {
        this.setState({modalInfoKids: true});
    };
    openInfoModalOld = () => {
        this.setState({modalInfoOld: true});
    };
    openInfoModalDiversity = () => {
        this.setState({modalInfoDiversity: true});
    };
    openInfoModalMedicine = () => {
        this.setState({modalInfoMedicine: true});
    };
    openMocksModal = () => {
        this.setState({mocksModal: true});
    };
    openMocksModal2 = () => {
        this.setState({mocksModal2: true});
    };
    closePresaleModal = () => {
        this.setState({openPresaleModal: false});
    };
    openPresaleModal = () => {
        ReactPixel.trackCustom('presaleButtonClicked');
        this.setState({openPresaleModal: true});
    };

    //DOCUMENTS - PDF
    triggerDocumentDownload = (requestedDocument) => {
        ReactPixel.trackCustom('downloadHelpDocument', {requestedDocument: requestedDocument});
        let link = document.createElement('a');
        link.href = './helpDocuments/' + requestedDocument + '.pdf';
        link.download = requestedDocument + '.pdf';
        link.dispatchEvent(new MouseEvent('click'));
    };
    handleDocumentRequest = (requestedDocument) => {
        let subscribedToNewsletter = this.checkNewsletterSubscription();
        if (subscribedToNewsletter) {
            this.triggerDocumentDownload(requestedDocument);
            ReactGA.ga('send', 'event', 'download', 'HelpDocument', '[' + requestedDocument + ']');
        } else {
            this.setState({openNewsletterModal: true, requestedDocument: requestedDocument});
            ReactGA.ga('send', 'event', 'click', 'openModalHelp');
            ReactPixel.trackCustom('newsletterModalOpened');
        }
    };
    //////////////////

    handleFabButtonVisibility = () => {

        // Set the state of hidden depending on scroll position
        // We only change the state if it needs to be changed
        if (window.scrollY < 160.00 && !this.state.fabHidden) {
            this.setState({fabHidden: true});
        } else if (window.scrollY > 160.00 && this.state.fabHidden) {
            this.setState({fabHidden: false});
        }
    };

    handleBuyModal = () => {
        //this.setState({openAuthModal: !this.state.openAuthModal});
        this.props.dispatch(show_buy_modal(!this.props.showBuyModal));

        if (this.props.showBuyModal) {
            ReactPixel.trackCustom('buyModalOpen', {description: 'El usuario ha empezado el flujo de compra'});
            ReactGA.modalview('/buyModalOpened');
            ReactGA.ga('send', 'event', 'click', 'buyModalOpen');
        }
    };

    handleLoginModal = () => {
        this.setState({openLoginModal: !this.state.openLoginModal});
    };
////////////////


    ////////////// RENDER REVIEWS //////////////
    getRanking(num) {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            if (num > 0) {
                stars.push(<StarIcon/>);
                num--;
            } else {
                stars.push(<StarEmptyIcon/>);
            }
        }
        return (
            <div className={importedStyles.starsContainer}>
                {stars}
            </div>
        );
    }

    getReview1() {
        return (
            <div className={importedStyles.reviewContainerContent}>
                <div className={importedStyles.reviewHead}>
                    <img
                        className={importedStyles.reviewLogo}
                        src={require('../../img/opinionesJesus.jpg')}
                        alt={'jesus'}
                    />
                    <p>Jesús Cordero</p>
                </div>
                <div className={importedStyles.reviewDataContainer}>
                    {this.getRanking(5)}
                    <Typography variant={"h6"} className={importedStyles.reviewTitle}>MUY ÚTIL</Typography>
                    <p>
                        Es de gran utilidad, y ya no solo para las personas como yo que padecemos de epilepsia sino para
                        cualquier persona. {/*que sufra un accidente o un percance.
                        Con un solo escaneo a la pulsera ya sabe la otra persona o los servicios sanitarios como actuar.*/}
                        A mi me fue muy útil :).
                    </p>
                </div>
            </div>
        );
    }

    getReview2() {
        return (
            <div className={importedStyles.reviewContainerContent}>
                <div className={importedStyles.reviewHead}>
                    <img
                        className={importedStyles.reviewLogo}
                        src={require('../../img/opinionesAlba.jpg')}
                        alt={'alba'}
                    />
                    <p>Alba Cabedo</p>
                </div>
                <div className={importedStyles.reviewDataContainer}>
                    {this.getRanking(5)}
                    <Typography variant={"h6"} className={importedStyles.reviewTitle}>SOBRESALIENTE</Typography>
                    <p>
                        Como profesional sanitario valoro mucho las herramientas que nos facilitan el trabajo en
                        situaciones de urgencia.
                        P{/*ulsID es un buen producto que recoge toda la información necesaria en esas determinadas
                        circunstancias, le diviso un futuro prometedor.*/} ¡Un sobresaliente!
                    </p>
                </div>
            </div>
        );
    }

    getReview3() {
        return (
            <div className={importedStyles.reviewContainerContent}>
                <div className={importedStyles.reviewHead}>
                    <img
                        className={importedStyles.reviewLogo}
                        src={require('../../img/opinionesFlor.jpg')}
                        alt={'flor'}
                    />
                    <p>Flor Palacios</p>
                </div>
                <div className={importedStyles.reviewDataContainer}>
                    {this.getRanking(4)}
                    <Typography variant={"h6"} className={importedStyles.reviewTitle}>PERFECTA PARA BEBÉS</Typography>
                    <p>
                        Yo tengo un bebé de un año y ya tenemos nuestra pulsera, un acierto para introducir datos sobre
                        salud, alergias, medicación... {/*y por si se pierde tenerlo localizado con todos sus datos y de
                        contacto mediante código QR.*/}
                        Lo recomiendo a todo el mundo!
                    </p>
                </div>
            </div>
        );
    }

    getDataIntroduction() {
        return (
            <div className={importedStyles.dataIntroduction}>
                <h1><AssignmentIcon/>CÓMO INTRODUCIR <span>&nbsp; MIS DATOS</span><AssignmentIcon/></h1>
                <p>
                    <List>
                        <ListItem className={importedStyles.liClass}>
                            <ListItemIcon className={importedStyles.dataIntroductionIcon}><BulletIcon/></ListItemIcon>
                            <p>
                                Al recibir tu pulsera deberás escanearla por primera vez o registrar su código único
                                pulsando&nbsp;
                                <a href={'https://mipulsid.com/codigo'} style={{color: '#0001d1'}}>aquí</a>
                            </p>
                        </ListItem>
                        <ListItem className={importedStyles.liClass}>
                            <ListItemIcon className={importedStyles.dataIntroductionIcon}><BulletIcon/></ListItemIcon>
                            <p>
                                Te pediremos un correo electrónico al que asociarla, donde llegarán todas las alertas e
                                información relacionada. </p>
                        </ListItem>
                        <ListItem className={importedStyles.liClass}>
                            <ListItemIcon className={importedStyles.dataIntroductionIcon}><BulletIcon/></ListItemIcon>
                            <p>
                                En todo momento podrás introducir o modificar los datos que desees desde nuestra
                                plataforma,
                                la mayoria de estos datos son totalmente opcionales, quedando en manos del usuario su
                                correcto rellenado y vericidad de los mismos.

                            </p>
                        </ListItem>
                    </List>
                </p>
            </div>
        );
    }

    ////////////// RENDER NEWS //////////////
    getNews1() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        className={importedStyles.newsLogo}
                        src={require('../../img/logoSER.png')}
                        webp={require('../../img/logoSER.webp')}
                        alt={'cadena ser vila-real'}
                    />
                </div>
                <div className={importedStyles.newsDataContainer}>
                    <h2>Cadena SER</h2>
                    <p>
                        El Ayuntamiento de Vila-real, con la colaboración de la Fundación Caixa Rural Vila-real –a
                        través del proyecto XiCaEs-
                        y la empresa local Sevi Systems, ganadora del último premio Globalis, han presentado esta mañana
                        la pulsera de seguridad y emergencia
                        ideada por la compañía para facilitar la identificación de colectivos de riesgo a las fuerzas de
                        seguridad
                    </p>
                    <a href={'https://cadenaser.com/emisora/2019/05/03/radio_castellon/1556903879_740483.html'}
                       target={'_blank'} rel="noreferrer">
                        Leer más
                    </a>
                </div>
            </div>
        );
    }

    getNews2() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        className={importedStyles.newsLogo}
                        src={require('../../img/logoCastellonPlaza.png')}
                        webp={require('../../img/logoCastellonPlaza.webp')}
                        alt={'diario castellón plaza'}
                    />
                </div>
                <div className={importedStyles.newsDataContainer}>
                    <h2>Diario Castellón Plaza</h2>
                    <p>Vila-real lanza una pulsera de emergencia pionera para facilitar la identificación de grupos en
                        riesgo</p>
                    <a href={'https://castellonplaza.com/vila-real-lanza-una-pulsera-de-emergencia-pionerapara-facilitar-la-identificacion-de-grupos-en-riesgo'}
                       target={'_blank'} rel="noreferrer">
                        Leer más
                    </a>
                </div>
            </div>
        );
    }

    getNews4() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        src={require('../../img/logoCuatro.png')}
                        webp={require('../../img/logoCuatro.webp')}
                        className={importedStyles.newsLogo}
                        alt={'cuatro'}
                    />
                </div>
                <div className={importedStyles.newsContainerContent}>
                    <h2>cuatro.com</h2>
                    <p>Los padres de una niña con epilsepsia salvan su vida gracias a una nota en su mochila. En ella se
                        leía:
                        «Hola, soy Luciana, si está leyendo esto, es que me he descompuesto. Soy epiléptica y tengo un
                        retraso, no se correctamente mis datos. Por favor, llame a mi familia para avisarles y luego a
                        la ambulancia al 061 y a la Policía Local al 092».</p>
                    <a href={'https://www.cuatro.com/noticias/sociedad/salvan-hija-epileptica-nota-mochila_0_2662275089.html'}
                       target={'_blank'} rel="noreferrer">
                        Leer más
                    </a>
                </div>
            </div>
        );
    }

    getNews3() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        className={importedStyles.newsLogo}
                        src={require('../../img/logoPoliciaZaragoza.jpg')}
                        webp={require('../../img/logoPoliciaZaragoza.webp')}
                        alt={'tweeter policia zaragoza'}
                    />
                </div>
                <div className={importedStyles.newsDataContainer}>
                    <h2>Twitter oficial Policía Local Zaragoza</h2>
                    <p>La policia local de Zaragoza acompaña a su residencia a una mujer con discapacidad intelectual
                        que caminaba desorientada.
                        El aviso de #GuardiaCivil, la asistencia de #061 y la identificación y traslado por policías del
                        #Sector3, hicieron esto posible.
                        #VelamosPorTi</p>
                    <a href={'https://twitter.com/policiazaragoza/status/1067679487847030785?s=20'} target={'_blank'}
                       rel="noreferrer">Leer
                        más</a>
                </div>
            </div>
        );
    }

    getNews5() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        className={importedStyles.newsLogo}
                        src={require('../../img/logoABC.jpg')}
                        webp={require('../../img/logoABC.webp')}
                        alt={'abc'}
                    />
                </div>
                <div className={importedStyles.newsDataContainer}>
                    <h2>Diario ABC</h2>
                    <p>Una niña con epilepsia salva su vida por una nota que dejaron sus padres en la mochila del
                        colegio. La niña viajaba en un vehículo que traslada a enfermos como ella cuando sufrió un
                        ataque epiléptico. Cuando los agentes fueron requeridos encontraron una nota en la mochila de la
                        niña.
                    </p>
                    <a href={'https://www.abc.es/sociedad/abci-nina-epilepsia-salva-vida-nota-dejaron-padres-mochila-colegio-201811201625_video.html'}
                       target={'_blank'} rel="noreferrer">
                        Leer más</a>
                </div>
            </div>
        );
    }

    getNewsFede() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        className={importedStyles.newsLogo}
                        src={require('../../img/logoFEDE.png')}
                        webp={require('../../img/logoFEDE.webp')}
                        alt={'fede'}
                    />
                </div>
                <div className={importedStyles.newsDataContainer}>
                    <h2>Federación Española de Epilepsia</h2>
                    <p>PulsID crea una pulsera que resulta esencial en una crisis de epilepsia.
                        Se trata de una herramienta útil para las personas con epilepsia ya que permite conocer tus
                        datos, contactar con la familia y acceder a información médica relevante en caso de emergencia
                        (una crisis en un lugar público), facilitando la actuación del personal de emergencia.
                    </p>
                    <a href={'http://www.fedeepilepsia.org/pulsid-crea-una-pulsera-permite-acceder-informacion-esencial-una-crisis-epilepsia/'}
                       target={'_blank'} rel="noreferrer">
                        Leer más</a>
                </div>
            </div>
        );
    }

    getNewsHeraldoAragon() {
        return (
            <div className={importedStyles.newsContainerContent}>
                <div>
                    <Image
                        className={importedStyles.newsLogo}
                        src={require('../../img/Heraldo-de-Aragon.png')}
                        webp={require('../../img/Heraldo-de-Aragon.webp')}
                        alt={'heraldo aragón'}
                    />
                </div>
                <div className={importedStyles.newsDataContainer}>
                    <h2>Heraldo de Aragón</h2>
                    <p>Un padre diseña una pulsera de emergencias tras ayudar a salvar a su hija una nota en la
                        mochila</p>
                    <a href={'https://www.heraldo.es/noticias/aragon/2019/11/28/un-padre-disena-una-pulsera-de-emergencias-tras-ayudar-a-salvar-a-su-hija-una-nota-en-la-mochila-1346349.html'}
                       target={'_blank'} rel="noreferrer">
                        Leer más</a>
                </div>
            </div>
        );
    }

    //////////////////////////////////

    renderCookies() {

        const action = (
            <div>
                <a className={importedStyles.moreInfoCookieText} target={"_blank"} href={'/cookies'}>
                    Más info
                </a>

                <Button size={"small"} variant={"contained"} className={importedStyles.cookiesMoreInfoButton}
                        onClick={() => this.props.dispatch(accept_cookies())}>
                    ACEPTAR
                </Button>
            </div>
        );

        if (this.props.showCookiesBanner) {

            return (
                <div className={importedStyles.cookiesLayer}>
                    <SnackbarContent
                        className={importedStyles.snackbar}
                        message={
                            'Utilizamos cookies propias y de terceros para elaborar información estadística conforme a nuestra política de cookies. ' +
                            '¿Nos dejas? 🍪'
                        }
                        action={action}
                    />
                </div>
            );
        }
    }

    renderShareModal() {
        ReactPixel.track('Purchase', {currency: 'EUR'});
        ReactGA.ga('send', 'event', 'click', 'Purchase');
        return (
            <Dialog open={this.state.paymentSuccessAndShareModal}
                    scroll={"body"}
                    onClose={() => this.setState({paymentSuccessAndShareModal: false})}>
                <DialogTitle>
                    Compra confirmada
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant={'subtitle1'}>
                            ¡Genial, pronto recibirás tu pulsera!
                            <br/>
                            Te hemos mandado la información necesaria a tu correo electrónico
                            <br/><br/>
                            Si quieres, mientras esperas puedes compartirlo con tus amigos, ¡Es más rápido que
                            hacerte un café!
                            <br/>
                        </Typography>
                        -- Con amor, el equipo de PulsID
                    </DialogContentText>
                    <div id={'shareButtonsBox'} className={importedStyles.shareButtonsBox}>
                        <IconButton>
                            <FacebookShareButton
                                className={importedStyles.shareButton}
                                url={'https://www.mipulsid.com'}
                                quote={'¡Ahora me siento más seguro gracias a mi PulsID! Consigue la tuya aquí:'}
                                hashtag={'#miPulsID'}
                            >
                                <FacebookIcon size={32} round/>
                            </FacebookShareButton>
                        </IconButton>
                        <IconButton>
                            <TwitterShareButton
                                url={'https://www.mipulsid.com'}
                                className={importedStyles.shareButton}
                                title={'¡Ahora me siento más seguro gracias a mi PulsID! Consigue la tuya aquí: '}
                                via={'SeviSystems'}
                                hashtags={['pulsID', 'MeSientoSeguro', 'SeviSystems']}
                            >
                                <TwitterIcon size={32} round/>
                            </TwitterShareButton>
                        </IconButton>
                        <IconButton>
                            <WhatsappShareButton
                                url={'https://www.mipulsid.com'}
                                className={importedStyles.shareButton}
                                title={'¡Ahora me siento más seguro gracias a mi PulsID! Consigue la tuya aquí: '}
                                separator={' '}
                            >
                                <WhatsappIcon size={32} round/>
                            </WhatsappShareButton>
                        </IconButton>
                    </div>
                    <Grid container justify={"center"} spacing={32}>
                        <Grid item xs={12}>
                            <p className={importedStyles.whatsappPostClaim}>
                                Descarga nuestra imagen informativa para que tus amigos sepan como ayudarte ... <br/>
                                ¡Y compártela por WhatsApp!
                            </p>
                        </Grid>
                        <Grid item xs={8} md={12} className={importedStyles.whatsappPostItem}>
                            <img
                                height={'80px'}
                                src={'https://scontent.fbcn9-1.fna.fbcdn.net/v/t1.0-9/67780579_532096904196567_4084867361419558912_n.png?_nc_cat=105&_nc_oc=AQkbN35YlR3O8cNQiQzbxDZ1QdHcyUZLVyj8Na0k0SbwgEF_mc3dywcLmF84mlnndGI&_nc_ht=scontent.fbcn9-1.fna&oh=302a7c1bceb9c95291c500690985401a&oe=5DD4BFC4'}
                                alt={'whatsapp post'}/>
                        </Grid>
                        <Grid item xs={8} md={12} className={importedStyles.whatsappPostItem}>
                            <Button
                                variant={"contained"} size={"small"}
                                className={importedStyles.downloadDocumentButton}
                                onClick={() => {
                                    let link = document.createElement('a');
                                    link.href = './pulsID-WhatsApp-info.png';
                                    link.download = './pulsID-WhatsApp-info.png';
                                    link.dispatchEvent(new MouseEvent('click'));
                                }}
                            >
                                <DownloadIcon className={importedStyles.documentsDownloadIcon}/>Descargar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({paymentSuccessAndShareModal: false})} color="primary">
                        No, gracias
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderFbLikeButton = () => {
        return (
            /*<div id={'fb-like-container'} className={importedStyles.FbLikeButtonContainer}>
                 <FacebookProvider appId="2328396927430786" debug={false}>
                     <Page href="https://www.facebook.com/Pulsid-463486781057580/" tabs="" hideCTA smallHeader showFacepile={true} />
                 </FacebookProvider>
            </div>*/
            <Button className={importedStyles.facebookLikeButton} size={"small"} color={"primary"} variant={"contained"}
                    onClick={() => window.open("https://www.facebook.com/mipulsid.arg", "_blank")}>
                <img src={require('../../img/fbLike.svg')} alt={'facebook like'}
                     className={importedStyles.facebookLike}/> Danos un like en Facebook
            </Button>
        )
    };

    updateWindowDimensions() {
        this.setState({width: window.outerWidth, height: window.innerHeight});
    }


    renderModalInfoOld = () => {
        return (
            <Dialog open={this.state.modalInfoOld}
                    fullScreen={mobile.matches}
                    scroll={"body"}
                    onClose={() => this.setState({modalInfoOld: false})}>
                <div className={importedStyles.modalInfoHeader}>
                    <DialogTitle>
                        Ayuda a los mayores
                    </DialogTitle>
                    <CloseIcon onClick={() => this.setState({modalInfoOld: false})}/>
                </div>
                <DialogContent>
                    <div className={importedStyles.modalInfoContent}>
                        <ul>
                            <li>Recibe un aviso cuando su PulsID sea escaneada.</li>
                            <li>Añade los contactos de sus seres queridos.</li>
                            <li>Usa la PulsID para estar más tranquilo si se pierde.</li>
                        </ul>
                        <img
                            src={require('../../img/mano.jpg')}
                            className={importedStyles.imageHand}
                            alt={'mano'}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({modalInfoOld: false});
                        this.props.dispatch(show_buy_modal(true));
                    }} color="primary">
                        Consígue la tuya
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };

    renderMocksModal = () => {
        return (
            <Dialog open={this.state.mocksModal}
                    fullScreen={mobile.matches}
                    scroll={"body"}
                    onClose={() => this.setState({mocksModal: false})}>
                <div className={importedStyles.modalInfoHeader}>
                    <DialogTitle>
                        Información mostrada al escanear
                    </DialogTitle>
                    <CloseIcon onClick={() => this.setState({mocksModal: false})}/>
                </div>
                <DialogContent>
                    <div className={importedStyles.modalInfoContent}>
                        <Image
                            src={require('../../img/mockupX.png')}
                            webp={require('../../img/mockupX.webp')}
                            style={{width: '100%'}}
                            alt={'header'}
                        />
                    </div>
                </DialogContent>
                {/*<DialogActions>
                    <Button variant={"contained"} onClick={() => {
                        this.setState({mocksModal: false});
                        this.props.dispatch(show_buy_modal(true));
                    }} style={{backgroundColor: '#FF6966', color: '#fff'}}>
                        Consígue la tuya
                    </Button>

                </DialogActions>*/}
            </Dialog>
        )
    };
    renderMocksModal2 = () => {
        return (
            <Dialog open={this.state.mocksModal2}
                    fullScreen={mobile.matches}
                    scroll={"body"}
                    onClose={() => this.setState({mocksModal2: false})}>
                <div className={importedStyles.modalInfoHeader}>
                    <DialogTitle>
                        Información mostrada al escanear
                    </DialogTitle>
                    <CloseIcon onClick={() => this.setState({mocksModal2: false})}/>
                </div>
                <DialogContent>
                    <div className={importedStyles.modalInfoContent}>
                        <Image
                            src={require('../../img/Mock-pasos.png')}
                            webp={require('../../img/Mock-pasos.webp')}
                            style={{width: '100%'}}
                            alt={'header'}
                        />
                    </div>
                </DialogContent>
                {/*<DialogActions>
                    <Button variant={"contained"} onClick={() => {
                        this.setState({mocksModal2: false});
                        this.props.dispatch(show_buy_modal(true));
                    }} style={{backgroundColor: '#FF6966', color: '#fff'}}>
                        Consígue la tuya
                    </Button>

                </DialogActions>*/}
            </Dialog>
        )
    };


    renderModalInfoDiversity = () => {
        return (
            <Dialog open={this.state.modalInfoDiversity}
                    fullScreen={mobile.matches}
                    scroll={"body"}
                    onClose={() => this.setState({modalInfoDiversity: false})}>
                <div className={importedStyles.modalInfoHeader}>
                    <DialogTitle>
                        Ayúdalos con pulsID
                    </DialogTitle>
                    <CloseIcon onClick={() => this.setState({modalInfoDiversity: false})}/>
                </div>
                <DialogContent>
                    <div className={importedStyles.modalInfoContent}>
                        <ul>
                            <li>Añade los pasos de emergencia para que te atiendan.</li>
                            <li>Añade en tu pulsID todas las enfermedades importantes.</li>
                            <li>Añade los contactos de sus seres queridos.</li>
                        </ul>
                        <Image
                            src={require('../../img/Mock-pasos.png')}
                            webp={require('../../img/Mock-pasos.webp')}
                            className={importedStyles.imageKid}
                            alt={'header'}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'}
                       target={'_blank'}
                       style={{textDecoration: 'none'}}>
                        <Button color="primary">
                            Consígue la tuya
                        </Button>
                    </a>
                </DialogActions>
            </Dialog>
        )
    };

    renderModalInfoMedicine = () => {
        return (
            <Dialog open={this.state.modalInfoMedicine}
                    fullScreen={mobile.matches}
                    scroll={"body"}
                    onClose={() => this.setState({modalInfoMedicine: false})}>
                <div className={importedStyles.modalInfoHeader}>
                    <DialogTitle>
                        Ayúdalos con pulsID
                    </DialogTitle>
                    <CloseIcon onClick={() => this.setState({modalInfoMedicine: false})}/>
                </div>
                <DialogContent>
                    <div className={importedStyles.modalInfoContent}>
                        <ul>
                            <li>Añade los pasos de emergencia para que te atiendan.</li>
                            <li>Añade en tu pulsID todas las enfermedades importantes.</li>
                            <li>Añade los contactos de sus seres queridos.</li>
                        </ul>
                        <Image
                            src={require('../../img/mockup.png')}
                            webp={require('../../img/mockup.webp')}
                            className={importedStyles.imageKid}
                            alt={'header'}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'}
                       target={'_blank'}
                       style={{textDecoration: 'none'}}>
                        <Button color="primary">
                            Consígue la tuya
                        </Button>
                    </a>
                </DialogActions>
            </Dialog>
        )
    };

    renderModalInfoKids = () => {
        return (
            <Dialog open={this.state.modalInfoKids}
                    fullScreen={mobile.matches}
                    scroll={"body"}
                    onClose={() => this.setState({modalInfoKids: false})}>
                <div className={importedStyles.modalInfoHeader}>
                    <DialogTitle>
                        Ayuda a los peques
                    </DialogTitle>
                    <CloseIcon onClick={() => this.setState({modalInfoKids: false})}/>
                </div>
                <DialogContent>
                    <div className={importedStyles.modalInfoContent}>
                        <ul>
                            <li>Añade los contactos de papi y mami en tu PulsID.</li>
                            <li>Usa la PulsID por si el peque se pierde.</li>
                            <li>Recibe un aviso cuando su PulsID sea escaneada.</li>
                        </ul>
                        <Image
                            src={require('../../img/mockup.png')}
                            webp={require('../../img/mockup.webp')}
                            className={importedStyles.imageKid}
                            alt={'header'}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'}
                       target={'_blank'}
                       style={{textDecoration: 'none'}}>
                        <Button color="primary">
                            Consígue la tuya
                        </Button>
                    </a>
                </DialogActions>
            </Dialog>
        )
    };

    getCTA(CTAStyle, bagdeStyle, copy) {
        return (
            // <Badge badgeContent={"13'99€"} classes={{badge: bagdeStyle}}>
            <div className={importedStyles.CTAContainer}>
                <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'} target={'_blank'}
                   style={{textDecoration: 'none'}}>
                    <Button
                        className={[CTAStyle, 'primaryCTA']}
                    >
                        Comprala en
                        <img style={{height: 60 , paddingLeft:"1rem"}} src={require("./../../img/mercadolibre.png")} alt="Mercado libre" />
                        {/* <TapIcon className={importedStyles.tapIcon}/> */}
                        {/*<div className={importedStyles.discountSticker}>-20%</div>*/}
                    </Button>
                </a>
            </div>
            // </Badge>
        );
    }

    getRandomSelledNumber = () => {
        let randomSelledNumberCookie = cookies.get('randomSelledNumberCookie');

        if (!randomSelledNumberCookie) {
            let randomNumber = Math.floor(Math.random() * 5) + 5;
            let expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 1);
            cookies.set('randomSelledNumberCookie', randomNumber, {expires: expireDate});

            return randomNumber;
        } else {
            return randomSelledNumberCookie;
        }
    };

    videoEnds = () => {
        // do things when video Ends
    };

    renderVideoPlayer = () => {
        const videoJsOptions = {
            fill: true,
            preload: true,
            aspectRatio: '16:9',
            autoplay: false,
            controls: true,
            sources: [
                /*{src: './videoWeb.mp4', type: 'video/mp4'},*/
                {type: "video/youtube", src: "https://www.youtube.com/embed/95OAT9NHxyI"}
            ],
            poster: './miniV2.png',
            techOrder: ["youtube"],
            onVideoEnds: this.videoEnds
        };
        return (
            <VideoPlayer {...videoJsOptions}/>
        )
    };

    getRandomBannerClaim = (selectedClaim) => {
        let content = '';
        let classes = '';
        switch (selectedClaim) {
            case 1:
                content = (
                    <React.Fragment>
                        {this.getRandomSelledNumber()} personas han comprado su PulsID en las últimas 24h 🕙
                    </React.Fragment>
                );
                classes = importedStyles.buyedClaimBox;
                break;

            case 2:
                content = (
                    <React.Fragment>
                        <b className={importedStyles.freeShippingClaim}>
                            {/*📦️*/} COMPRA YA: <b className={importedStyles.countDown}>
                            {this.renderCountDown()}
                        </b> ENVÍO GRATIS {/*⌚*/}
                        </b>
                    </React.Fragment>
                );
                classes = importedStyles.buyNowClaimBox;
                break;
            default:
                content = (
                    <React.Fragment>
                        {this.getRandomSelledNumber()} personas han comprado su PulsID en las últimas 24h 🕙
                    </React.Fragment>
                );
                classes = importedStyles.buyedClaimBox;
                break;

        }
        return (
            <HeadBanner content={content} classes={classes} animation={'fadeInLeft'} endAnimation={'fadeOutRight'}/>
        );

    };

    renderCountUp = () => {
        return (
            <CountUp start={1200} end={3000} duration={6} delay={3} useEasing={false}
                     onEnd={({pauseResume, reset, start, update}) => {
                     }}/>
        )
    };
    renderCountDown = () => {
        let currentDate = moment();
        let finishDate = moment('9 Feb 2020');

        if (localStorage.getItem('freeShippingLimitDate')) {
            finishDate = moment(localStorage.getItem('freeShippingLimitDate')).format();
        }
        if (currentDate.isAfter(finishDate)) {
            finishDate = moment(currentDate.add('48', 'hours')).format();
            localStorage.setItem('freeShippingLimitDate', finishDate);
        }


        return (
            <Countdown daysInHours={true} date={finishDate} /*renderer={this.countDownCustomRender} *//>
        )
    };
    countDownCustomRender = ({days, hours, minutes, seconds}) => {
        return <span> {days}d : {hours}h : {minutes}m : {seconds}s </span>;
    };


    render() {
        return (
            <div className={importedStyles.Landing}>
                <Helmet>
                    <title>PulsID</title>
                    <meta name="description"
                          content="Protegé a tus seres queridos con PulsID, nuestra pulsera contra emergencias"/>
                    <meta name="keywords"
                          content="pulsid, pulsera, emergencia, proteger, alzheimer, epilepsia, qr ,hospital, residencia"/>
                </Helmet>
                <Grid container className={importedStyles.headContainer} id={"headSection"}>
                    <Grid item xs={12} md={6} className={importedStyles.headContainerContent}>
                        <h1>La <b>pulsera</b> que te ayuda en caso de <b>emergencia</b></h1>
                        <h2>Cuidá de los tuyos.</h2>
                        {this.getCTA(importedStyles.primaryCTAButton, importedStyles.pulsidPriceBadgeDesktop, 'Consíguela ya')}
                    </Grid>
                    <Grid item xs={12} md={6} className={importedStyles.videoPlayerGridItem}>
                        <div>
                            {this.renderVideoPlayer()}
                        </div>
                    </Grid>


                    {/*PROMO OFFER BANNERS*/}
                    {/*   <Grid item xs={12} className={importedStyles.offerBanner} id={'offerBanner'}>
                                <Grid item xs={10} className={importedStyles.offerClaim}>
                                    Oferta navideña por tiempo limitado, consigue un 15% de descuento con el código <br/>
                                    <span aria-label={'arbol de navidad'} role={'img'}>🎄</span>
                                    <b style={{margin: '0.5rem'}}>NAVIDADPULSID</b>
                                    <span aria-label={'arbol de navidad'} role={'img'}>🎄</span>
                                </Grid>
                                <Grid item xs={10}  className={importedStyles.offerBannerCountdown}>
                                <Countdown date={new Date('31 Dec 2019')} renderer={this.countDownRender} />
                                </Grid>
                                <Grid item xs={10} className={importedStyles.offerButtonBox}>
                                    <Button
                                        variant={"contained"} className={importedStyles.offerBannerGetItButton}
                                        onClick={() => {
                                            history.push('/?shop=true&promoCode=NAVIDADPULSID');
                                            window.location.reload();
                                        }}
                                        //onClick={this.openPresaleModal}
                                    >
                                        Obtener descuento
                                    </Button>
                                </Grid>
                                <Grid item className={importedStyles.closeOfferBanner}>
                                <IconButton  onClick={()=>document.getElementById('offerBanner').style.display = 'none'}>
                                    <CloseIcon className={importedStyles.closeOfferBannerIcon}/>
                                </IconButton>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} className={importedStyles.offerBannerMobile} id={'offerBannerMobile'}>
                                <span>
                                    ¡Oferta navideña! -15% con el código <b>NAVIDADPULSID</b>
                                    <span aria-label={'arbol de navidad'} role={'img'} style={{marginLeft: '1rem'}}>🎄 🎅</span>
                                </span>

                                <span>La oferta termina en:</span> <Countdown date={new Date('15 Jul 2019')} renderer={this.countDownRender} />
                            </Grid>*/}
                    {/* END OFFER BANNERS*/}

                </Grid>
                {/*FIN HEAD SECTION*/}
                <div className={importedStyles.peopleContainer} id={"protect"}>
                    <Image
                        className={importedStyles.fondoOlasImg}
                        src={require('../../img/fondoolas.png')}
                        webp={require('../../img/fondoolas.webp')}
                    />
                    {this.renderModalInfoKids()}
                    {this.renderModalInfoOld()}
                    {this.renderModalInfoMedicine()}
                    {this.renderModalInfoDiversity()}
                    <div className={importedStyles.peopleContainerContent}>
                        <h1>PROTEGE LO QUE MÁS TE IMPORTA</h1>
                        <p>
                            PulsID es vital para identificar y atender a los tuyos en caso de que ocurra un
                            accidente o tu ser querido se desoriente. PulsID es ideal para:
                        </p>
                        <div className={importedStyles.peopleIconContainer}>
                            <div className={importedStyles.peopleIconLeft}>
                                <div className={importedStyles.peopleIconContent} onClick={this.openInfoModalKids}>
                                    <Image
                                        src={require('../../img/s2.png')}
                                        webp={require('../../img/s2.webp')}
                                        alt={'header'}
                                    />
                                    <p>Niños</p>
                                </div>
                                <div className={importedStyles.peopleIconContent} onClick={this.openInfoModalDiversity}>
                                    <Image
                                        src={require('../../img/s4.png')}
                                        webp={require('../../img/s4.webp')}
                                        alt={'header'}
                                    />
                                    <p>Personas con diversidad funcional</p>
                                </div>
                            </div>
                            <div className={importedStyles.peopleIconRigth}>
                                <div className={importedStyles.peopleIconContent} onClick={this.openInfoModalOld}>
                                    <Image
                                        src={require('../../img/s1.png')}
                                        webp={require('../../img/s1.webp')}
                                        alt={'header'}
                                    />
                                    <p>Mayores</p>
                                </div>
                                <div className={importedStyles.peopleIconContent} onClick={this.openInfoModalMedicine}>
                                    <Image
                                        src={require('../../img/s3.png')}
                                        webp={require('../../img/s3.webp')}
                                        alt={'header'}
                                    />
                                    <p>Pacientes con tratamientos especiales</p>
                                </div>
                            </div>
                        </div>
                        <Typography variant={"h1"} className={importedStyles.countUp}>
                            + {this.renderCountUp()}
                        </Typography>
                        <Typography variant={"h4"} style={{color: '#fff'}}>
                            pulseras vendidas
                        </Typography>
                    </div>
                </div>
                <div className={importedStyles.ownBraceletsContainer}>
                    <div className={importedStyles.content}>
                        <div className={importedStyles.braceletsContainerImg}>
                            <div className={importedStyles.braceletsContainerImgBracelet}>
                                <Image
                                    src={require('../../img/pulsidBracelet.png')}
                                    webp={require('../../img/pulsidBracelet.webp')}
                                    alt={'header'}
                                />
                            </div>
                            <Image
                                className={importedStyles.braceletsContainerImgHandQR}
                                src={require('../../img/handQR.png')}
                                webp={require('../../img/handQR.webp')}
                                alt={'header'}
                            />
                        </div>
                    </div>
                </div>
                <div className={importedStyles.sampleContainer}>
                    <div className={importedStyles.contentList}>
                        <h1>Escaneá el código QR y tendrás toda la información al instante</h1>

                        <ul className={importedStyles.sampleList}>
                            <li>
                                <Image
                                    src={require('../../img/arrowIcon.png')}
                                    webp={require('../../img/arrowIcon.webp')}
                                    alt={'header'}
                                />
                                <span>Accedé a información vital en caso de emergencia</span>
                            </li>
                            <li>
                                <Image
                                    src={require('../../img/arrowIcon.png')}
                                    webp={require('../../img/arrowIcon.webp')}
                                    alt={'header'}
                                />
                                <span>Desde cualquier dispositivo</span>
                            </li>
                            <li>
                                <Image
                                    src={require('../../img/arrowIcon.png')}
                                    webp={require('../../img/arrowIcon.webp')}
                                    alt={'header'}
                                />
                                <span>Sin necesidad de ninguna app</span>
                            </li>
                        </ul>
                    </div>
                    <div className={importedStyles.contentMock}>
                        <Image
                            className={importedStyles.mockContainerImg}
                            src={require('../../img/mockQR.png')}
                            webp={require('../../img/mockQR.webp')}
                            alt={'header'}
                        />
                        <Image
                            onClick={this.openMocksModal2}
                            className={importedStyles.mockStepsView}
                            src={require('../../img/Mock-pasos.png')}
                            webp={require('../../img/Mock-pasos.webp')}
                            alt={'mockup-pasos'}
                        />
                        <Image
                            onClick={this.openMocksModal}
                            className={importedStyles.sampleImg}
                            src={require('../../img/mockupX.png')}
                            webp={require('../../img/mockupX.webp')}
                            alt={'mockup-datos'}
                        />
                    </div>
                    {this.renderMocksModal()}
                    {this.renderMocksModal2()}
                </div>


                {/* <div id={"wat"} className={importedStyles.ownBraceletsContainer}>
                    <div className={importedStyles.content}>
                        <div className={importedStyles.braceletsContainerImg}>
                            <div className={importedStyles.braceletsContainerImgBracelet}>
                                <Image
                                    src={require('../../img/pulsidBracelet.png')}
                                    webp={require('../../img/pulsidBracelet.webp')}
                                    alt={'header'}
                                />
                            </div>
                            <Image
                                className={importedStyles.braceletsContainerImgHandQR}
                                src={require('../../img/handQR.png')}
                                webp={require('../../img/handQR.webp')}
                                alt={'header'}
                            />
                        </div>
                        <div className={importedStyles.ownBraceletsTextContainer}>
                            <h1>Nuestras pulseras</h1>
                            <p>PulsID es una pulsera con un código QR en su interior que, simplemente, al
                                escanearlo con cualquier dispositivo móvil, te dirige a una página con los
                                datos médicos del usuario, sin necesidad de descargar ninguna aplicación.</p>
                            <p>
                                Nuestra pulsera permite conocer tus datos, contactar con tu familia y
                                acceder a información médica relevante en caso de emergencia, haciendo
                                posible una rápida actuación por parte del personal de emergencia.</p>
                            {!this.state.showWhat && <p className={importedStyles.seeMoreText}
                                                        onClick={() => this.setState({showWhat: true})}>Ver más...</p>}
                            {this.state.showWhat && <React.Fragment><p>
                                Esta información puede ser vital para cualquier persona, pero especialmente,
                                es recomendable para identificar a niños, mayores, personas con diversidad
                                funcional o pacientes que requieran de tratamientos especiales.
                            </p>
                                <p>Para obtener tu PulsId solo pagarás una vez, no existe ningún coste por el servicio o
                                    mantenimiento. Y nos comprometemos a nunca comercializar tus datos privados.</p>
                            </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className={importedStyles.contentVideo}>

                        <div className={importedStyles.iframeVideo}>
                            <ResponsiveEmbed src='https://www.youtube.com/embed/qXCEB2Jppbk?loop=1&playlist=qXCEB2Jppbk'
                                             allowFullScreen ratio='16:9'/>
                        </div>
                        <Image
                            className={importedStyles.braceletsContainerImgArrowLeft}
                            src={require('../../img/arrowLeft.png')}
                            webp={require('../../img/arrowLeft.webp')}
                            alt={'header'}
                        />
                        <Image
                            className={importedStyles.braceletsContainerImgArrowMobile}
                            src={require('../../img/arrowMobile.png')}
                            webp={require('../../img/arrowMobile.webp')}
                            alt={'header'}
                        />
                    </div>
                </div>*/}

                <Grid container spacing={4} className={importedStyles.reviewsContainer}>
                    <Grid item xs={12} md={3} className={importedStyles.reviewGridItem}>
                        {this.getReview1()}
                    </Grid>
                    <Grid item xs={12} md={3} className={importedStyles.reviewGridItem}>
                        {this.getReview2()}

                    </Grid>
                    <Grid item xs={12} md={3} className={importedStyles.reviewGridItem}>
                        {this.getReview3()}
                    </Grid>
                    <Grid item xs={12} md={10}>
                        {this.getDataIntroduction()}
                    </Grid>
                </Grid>

                <Grid container justify={'center'} className={importedStyles.newsletterSectionGrid} spacing={0}>
                    <Grid item xs={10} md={8}>
                        <NewsletterSection/>
                    </Grid>
                </Grid>

                <Grid container spacing={32} className={importedStyles.weHelpYouSection} id={'weHelpYou'}>
                    <Grid item xs={12}>
                        <h1>TE AYUDAMOS</h1>
                        <p>
                            Aquí tenés unos documentos muy útiles y explicativos
                        </p>
                    </Grid>
                    <Grid item xs={8} md={2} className={importedStyles.documentsButtonContainer}>
                        <Button variant={"contained"} size={"large"} fullWidth
                                className={importedStyles.downloadDocumentButton}
                                onClick={() => this.handleDocumentRequest('Ayuda PulsID-Emergencia')}>
                            <DownloadIcon className={importedStyles.documentsDownloadIcon}/>¿Cómo ayudo a alguien en
                            caso de emergencia?
                        </Button>
                    </Grid>
                    <Grid item xs={8} md={2} className={importedStyles.documentsButtonContainer}>
                        <Button variant={"contained"} size={"large"} fullWidth
                                className={importedStyles.downloadDocumentButton}
                                onClick={() => this.handleDocumentRequest('Ayuda PulsID-Epilepsia')}>
                            <DownloadIcon className={importedStyles.documentsDownloadIcon}/>Tratamiento y ayuda para
                            epilépticos
                        </Button>
                    </Grid>
                    <Grid item xs={8} md={2} className={importedStyles.documentsButtonContainer}>
                        <Button variant={"contained"} size={"large"} fullWidth
                                className={importedStyles.downloadDocumentButton}
                                onClick={() => this.handleDocumentRequest('Ayuda PulsID-Nino Perdido')}>
                            <DownloadIcon className={importedStyles.documentsDownloadIcon}/>¿Qué hago si el niño se
                            pierde?
                        </Button>
                    </Grid>
                </Grid>
                {/*
                <Grid container spacing={32} className={importedStyles.whatsappPostSection} id={'weHelpYou'}>
                    <Grid item xs={12}>
                        <h1>¿Ya tienes tu pulsID?</h1>
                        <p className={importedStyles.whatsappPostClaim}>
                            Descarga nuestra imagen informativa para que tus amigos sepan como ayudarte ... <br/>
                            ¡Y compártela por WhatsApp!
                        </p>
                    </Grid>
                    <Grid item xs={8} md={12} className={importedStyles.whatsappPostItem}>
                        <Image
                            className={importedStyles.whatsappPostImage}
                            src={'./pulsID-WhatsApp-info.png'}
                            webp={'./pulsID-WhatsApp-info.webp'}
                            alt={'whatsapp post'}
                        />
                    </Grid>
                    <Grid item xs={8} md={12} className={importedStyles.whatsappPostItem}>
                        <Button
                            variant={"contained"} size={"large"}
                            className={importedStyles.downloadDocumentButton}
                            onClick={() => {
                                let link = document.createElement('a');
                                link.href = './pulsID-WhatsApp-info.png';
                                link.download = './pulsID-WhatsApp-info.png';
                                link.dispatchEvent(new MouseEvent('click'));
                            }}
                        >
                            <DownloadIcon className={importedStyles.documentsDownloadIcon}/>Descargar
                        </Button>
                    </Grid>
                </Grid>

                <div id={'history'} className={importedStyles.historyContainer}>
                    <div className={importedStyles.historyContainerContent}>
                        {this.getCTA(importedStyles.secondaryCTAButton, importedStyles.pulsidPriceBadgeAlways, 'Consíguela ya')}
                        <h1>¿Cómo surge PulsID?</h1>
                        <p>Cuando éramos pequeños, mi padre nos confeccionó a mi hermano y a mí
                            una chapa con datos relevantes que permitiera identificarnos en caso de
                            emergencia.</p>
                        <p>
                            Cuando mi hija con diversidad funcional comenzó a sufrir desmayos
                            repentinos decidí crear para ella una chapa como la mía que informara de su
                            situación y permitiera ayudarla de forma inmediata.
                        </p>
                        {!this.state.showHistory &&
                        <p className={importedStyles.seeMoreText} onClick={() => this.setState({showHistory: true})}>Ver
                            más...</p>}
                        {this.state.showHistory &&
                        <p>
                            Sin embargo, conscientes de la necesidad de muchas personas como
                            nosotros de proteger a los suyos, decidimos crear PulsID, un
                            proyecto social, asequible y fácil de usar, que permite incluir no solo los datos
                            de contacto de la persona, sino toda la información médica relevante para
                            acceder a ella con un sencillo gesto.</p>
                        }
                    </div>
                    <div className={importedStyles.historyImageContainer}>
                        <Image
                            src={require('../../img/ancianos.png')}
                            webp={require('../../img/ancianos.webp')}
                        />
                    </div>
                </div>
                <div className={importedStyles.enviromentContainer}>
                    <div className={importedStyles.enviromentContainerContent}>
                        <div className={importedStyles.enviromentImageContainer}>
                            <Image
                                src={require('../../img/eco.png')}
                                webp={require('../../img/eco.webp')}
                            />
                        </div>
                        <div className={importedStyles.enviromentTextContainer}>
                            <h1>COMPROMISO MEDIOAMBIENTAL</h1>
                            <h3>En 2050 se prevé que habrá más plásticos que peces en el océano.</h3>
                            <p>En los últimos 50 años la producción mundial de plástico ha pasado
                                de 1,5 millones de toneladas al año a 245 toneladas.
                                Una gran parte de estas toneladas van a parar a nuestros mares y
                                océanos, convirtiéndolos en los mayores vertederos de plásticos y
                                causando daños irreparables a las especies marinas que habitan en
                                ellos.
                                En PulsID somos conscientes de esta problemática y, por ello, en
                                nuestro compromiso con el medio ambiente <b>decidimos utilizar un packaging de cartón
                                    eco-friendly </b> y, así, evitar crear
                                innecesariamente más de 2000 kg de plástico al año.</p>
                        </div>

                    </div>
                </div>*/}
                <div className={importedStyles.newsContainer}>
                    <Carousel
                        wrapAround
                        dragging={false}
                        renderCenterLeftControls={({previousSlide}) => (
                            <button onClick={previousSlide}><BeforeArrowIcon/></button>
                        )}
                        renderCenterRightControls={({nextSlide}) => (
                            <button onClick={nextSlide}><NextArrowIcon/></button>
                        )}
                        renderBottomCenterControls={({currentSlide}) => {
                        }}
                    >
                        {this.getNewsFede()}
                        {this.getNewsHeraldoAragon()}
                        {this.getNews1()}
                        {this.getNews2()}
                        {this.getNews5()}
                    </Carousel>
                </div>
                {this.getCTA(importedStyles.primaryCTAButton, importedStyles.pulsidPriceBadgeAlways, 'Consíguela ya')}

                <div className={importedStyles.footerContainer}>
                    <div className={importedStyles.footerContent}>
                        <span style={{textAlign: 'center'}}> Si tienes alguna duda o deseas más información contactanos en <br/>
                        <a target={'_blank'} href={'mailto:hola@gmail.com'}>hola@mipulsid.com</a>
                        </span>
                        <a className={importedStyles.sevi} href={'https://sevisl.com/'}>Copyright © 2020 Sociedad
                            Europea de
                            Verificación de Identidad </a>
                        <br/>
                        <div className={importedStyles.footerLawsContainer}>
                            <p className={importedStyles.privacy} onClick={() => history.push('/law')}>Aviso legal</p>
                            <p> y </p>
                            <p className={importedStyles.privacy} onClick={() => history.push('/privacy')}>política de
                                privacidad</p>
                        </div>
                        <Image
                            className={importedStyles.seviLogo}
                            src={require('../../img/logoBrandBlue.png')}
                            webp={require('../../img/logoBrandBlue.webp')}
                            alt={'SEVI'}
                        />
                        <div className={importedStyles.footerLogosContainer}>
                            {/*<Image
                                style={{width: 75}}
                                src={require('../../img/enisa.png')}
                                webp={require('../../img/enisa.webp')}
                            />
                            <Image
                                style={{width: 125}}
                                src={require('../../img/logoIvace.png')}
                                webp={require('../../img/logoIvace.webp')}
                            />
                            <Image
                                style={{width: 125}}
                                src={require('../../img/federLogo.png')}
                                webp={require('../../img/federLogo.webp')}
                            />
                            <Image
                                style={{width: 125}}
                                src={require('../../img/logoGeneralitat.png')}
                                webp={require('../../img/logoGeneralitat.webp')}
                            />*/}
                            <Image
                                style={{width: '100%'}}
                                src={require('../../img/logosFooterComposition.png')}
                                webp={require('../../img/logosFooterComposition.webp')}
                            />
                        </div>

                        <Typography variant={"subtitle2"}
                                    style={{textAlign: 'center', width: '75%', marginTop: '1rem'}}>
                            Esta actuación está cofinanciada por la Unión Europea a través del Programa Operativo FEDER de la
Comunitat Valenciana 2014-2020
                        </Typography>
                    </div>
                </div>
                <div className={importedStyles.finalImgContainer}>
                    <Image
                        src={require('../../img/fondoolas2.png')}
                        webp={require('../../img/fondoolas2.webp')}
                    />
                    {/*<p className={importedStyles.asterisco}>*IVA incluido. Coste de 2€ para envío a la península.</p>*/}
                </div>

                {/*{!this.state.fabHidden &&
                <Fab
                    variant="extended"
                    aria-label="Consigue tu pulsera"
                    size={"large"}
                    className={this.props.logged ? importedStyles.floatingButtonGetItLogged : importedStyles.floatingButtonGetIt}
                    onClick={this.handleBuyModal}
                    //onClick={this.openPresaleModal}
                >
                    <ShoppingIcon className={importedStyles.floatingButtonGetItIcon}/>
                    Consíguela
                </Fab>
                }*/}

                {/*{this.props.logged &&
                <Button
                    variant="extended"
                    aria-label="activa tu pulsera"
                    size={"large"}
                    className={importedStyles.floatingActiveBraceletButton}
                    onClick={() => history.push('/codigo')}
                >
                    <ActiveIcon className={importedStyles.activeIcon}/>
                    Activar nueva pulsera
                </Button>
                }*/}

                <Snack/>
                {this.state.paymentSuccessAndShareModal && this.renderShareModal()}

                <Navbar path={window.location.pathname} hidden={true}/>
                {this.renderCookies()}

                <BuyModals open={this.props.showBuyModal} handleModal={this.handleBuyModal}
                           func_success={this.handleLoginModal} newBracelet={this.state.newBracelet}
                           promoCode={this.state.promoCode}/>
                <LoginModal open={this.state.openLoginModal} handleModal={this.handleLoginModal}/>
                <PresaleModal open={this.state.openPresaleModal} onClose={this.closePresaleModal}/>
                <BeforeCloseAlertModal open={this.state.openBeforeCloseAlertModal} onClose={this.closeAlertModal}/>
                <NewsletterModal
                    open={this.state.openNewsletterModal}
                    onClose={() => this.setState({openNewsletterModal: false})}
                    requestedDocument={this.state.requestedDocument}
                />

                <TidioChat show={!this.props.showBuyModal}/>

            </div>
        );
    }
}

const mapStateToProps = ({authReducer, braceletListReducer}) => {
    return ({
        user: authReducer.user,
        showCookiesBanner: authReducer.showCookiesBanner,
        logged: authReducer.logged,
        braceletList: braceletListReducer.braceletList,
        showBuyModal: authReducer.showBuyModal
    });
};
export default connect(mapStateToProps)(Landing);