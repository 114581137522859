import React from 'react';
import importedStyles from "./Detail.module.sass";
import baseRoute from "../../helpers/baseRoute";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
    AccountCircle,
    Comment,
    ContactPhone,
    CreditCard,
    LocalHospital,
    LocalPharmacy,
    NotInterested,
    Place,
    Warning
} from '@material-ui/icons';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import AssignModal from "../Modals/AssignMailModal/AssignModal";
import PatientModal from "../Patient/PatientModal";
import PhotosDialog from "../../components/PhotosDialog/PhotosDialog";
import Navbar from "../../components/Navbar/Navbar";
import ListItemText from "@material-ui/core/es/ListItemText/ListItemText";
import connect from "react-redux/es/connect/connect";
import {handle_view_detail} from "../BraceletList/BraceletListActions";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import {Avatar, DialogActions} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import EmergencyStepsModal from "../Patient/EmergencyStepsModal/EmergencyStepsModal";
import {close_assign_modals} from "../Patient/PatientActions";

class Detail extends React.Component {

    state = {
        error: false,
        errorPatient: false,
        gettingData: true,
        assignModal: false,
        photosModal: false,
        hasPatientData: false,
        disabledBracelet: false,
        showSnack: false,
        photos: [],
        patient: {
            name: 'Maria',
            surname: 'Lluch',
            age: 16,
            allergies: "[\"patata\",\"penicilina\"]",
            medicines: 'Pastillas ',
            diagnosis: 'Epilepsia',
            observation: 'Grupo sanguíneo A+',

            address: {
                city: 'Cariñena',
                region: 'Zaragoza',
                country: 'España'
            },
            documents: {
                document1: {
                    type: 'DNI',
                    number: '54001200X'
                },
                document2: {
                    type: 'SIP',
                    number: 'e2b32001200X'
                }
            },
            emergency_contacts: {
                contact1: {
                    name: 'Diego',
                    phone: '612332112'
                },
                contact2: {
                    name: 'Isabel',
                    phone: '61212154'
                }
            },
        },
        showEmergencyStepsModal: false,
        emergencySteps: [],
    };


    componentDidMount() {
        const uuidBracelet = this.props.match.params.id;
        let noNotify = this.props.match.params.noNotify ? true : false;

        if (!this.props.view && !noNotify){ //If is not a simulated scan
            this.updateScanCounter();
        }

        fetch(baseRoute + 'api/patients/getAllPatientData', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidBracelet: uuidBracelet,
                    view: this.props.view,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);

            if (response.error) {

                switch (response.type) {
                    case 'noPatient':
                        this.showEmergencySteps(response.emergencySteps);
                        this.setState({
                            errorPatient: true,
                            gettingData: false,
                            showSnack: true
                        });
                        break;
                    case 'noAssigned':
                        this.setState({
                            errorPatient: true,
                            gettingData: false,
                            assignModal: true,
                        });
                        break;
                    case 'disabledBracelet':
                        this.setState({
                            disabledBracelet: true,
                            gettingData: false,
                            errorPatient: true,
                        });
                        break;
                    default:
                        this.setState({
                            error: true,
                            gettingData: false
                        });
                        break;
                }
            } else {
                this.showEmergencySteps(response.emergencySteps);
                this.setState({
                    patient: response.patient,
                    error: false,
                    hasPatientData: true,
                    gettingData: false,
                    showSnack: true
                });
            }
        }).catch((error) => {
            console.error('Error: ', error);
            this.setState({
                error: true,
                gettingData: false
            });
        });

        //Set a listener to clean redux data on close window event
        this.setCloseWindowListener();
    }
    componentWillUnmount(): void {
        this.props.dispatch(handle_view_detail(false));
        this.props.dispatch(close_assign_modals());
    }

    updateScanCounter = () => {
        fetch(baseRoute + 'api/utils/updateScanCounter', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidBracelet: this.props.match.params.id
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                throw new Error(response.error);
            } else {
                console.info('Scan counter updated')
            }
        }).catch((error) => {
            console.error('Error updating scan counter: ', error);
        });
    };

    setCloseWindowListener(){

        //When this window is about to close, clean redux data
        window.addEventListener('beforeunload', function(event) {
            event.preventDefault();
            this.props.dispatch(handle_view_detail(false));
            this.props.dispatch(close_assign_modals());
        });
    }

    showEmergencySteps(emergencySteps){
        //check if there are emergencySteps and show its modal
        if (emergencySteps.length > 0){
            this.setState({showEmergencyStepsModal: true, emergencySteps: emergencySteps});
        }
    }

    renderPatientPhoto = () => {
        return (
            <ListItem className={importedStyles.patientPhotoContainer}>
                <Avatar alt="Foto del paciente" src={this.state.patient.photo_url}  className={importedStyles.patientPhoto}/>
            </ListItem>
        );
    }
    getDocuments() {
        const patient = this.state.patient;
        let documents = [];
        Object.keys(patient.documents).forEach((doc) => {
            documents.push(
                <ListItem className={importedStyles.document} key={doc}>
                    <ListItemIcon>
                        <CreditCard className={importedStyles.ListIcon}/>
                    </ListItemIcon>
                    <ListItemText
                        className={importedStyles.documentText}
                        primary={patient.documents[doc].number}
                        secondary={
                            <div className={importedStyles.documentSecondaryBox}>
                                <span className={importedStyles.documentTypeText}>{patient.documents[doc].type}</span>
                                <span className={importedStyles.documentViewPhotos}
                                      onClick={() => this.handlePhotosModal(patient.documents[doc].photos.split(', '))}
                                      style={patient.documents[doc].photos ? {} : {visibility: 'hidden'}}
                                >
                                    Ver imagen/es
                                </span>
                            </div>
                        }/>
                </ListItem>
            );
        });
        return documents;
    }
    getBasicData() {
        const patient = this.state.patient;
        return (
            <List>

                <ListItem className={importedStyles.header}>
                    <ListItemIcon children={<span/>}/>
                    <h1>
                        Datos básicos
                    </h1>
                </ListItem>
{/*
                {this.state.patient.photo_url && this.renderPatientPhoto()}
*/}

                <ListItem>
                    <ListItemIcon>
                        <AccountCircle/>
                    </ListItemIcon>
                    <p>
                        {patient.name} {patient.surname}, {patient.age} años
                    </p>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Place/>
                    </ListItemIcon>
                    <p>
                        {patient.address.city}, {patient.address.region}, {patient.address.country}
                    </p>
                </ListItem>
                {this.getDocuments()}
            </List>
        );
    }
    getMedicData() {
        const patient = this.state.patient;
        return (
            <List>
                <ListItem className={importedStyles.header}>
                    <ListItemIcon children={<span/>}/>
                    <h1>
                        Datos médicos
                    </h1>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <LocalHospital/>
                    </ListItemIcon>
                    <h3>Padece:</h3>
                    <p>{patient.diagnosis ? patient.diagnosis : '---'}</p>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <NotInterested/>
                    </ListItemIcon>
                    <h3>Alergia a:</h3>
                    <span>{patient.allergies ? patient.allergies : '---'}</span>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <LocalPharmacy/>
                    </ListItemIcon>
                    <h3>Toma:</h3>
                    <p>{patient.medicines ? patient.medicines : '---'}</p>
                </ListItem>
                <ListItem className={importedStyles.medicObservationRow}>
                    <ListItemIcon>
                        <Comment/>
                    </ListItemIcon>
                    <div className={importedStyles.medicObservation}>
                        <h3>Otros datos:</h3>
                        <p>{patient.observation ? patient.observation : '---'}</p>
                    </div>
                </ListItem>
            </List>
        );
    }
    getEmergencyData() {
        const patient = this.state.patient;
        let contacts = [];
        Object.keys(patient.emergency_contacts).forEach((contact) => {

            let primaryText = '';

            if (patient.emergency_contacts[contact].relationship) {
                primaryText = <span>{patient.emergency_contacts[contact].name}
                    <i className={importedStyles.relationshipText}>
                            ({patient.emergency_contacts[contact].relationship})
                        </i>
                    </span>
            } else {
                primaryText = patient.emergency_contacts[contact].name;
            }
            contacts.push(
                <ListItem className={importedStyles.emergencyDataRow} key={contact}>
                    <ListItemIcon>
                        <ContactPhone/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="h5" gutterBottom> {primaryText}</Typography>}
                                  secondary={
                                      <a href={'tel: ' + patient.emergency_contacts[contact].prefix + patient.emergency_contacts[contact].phone}>+ {patient.emergency_contacts[contact].prefix} {patient.emergency_contacts[contact].phone} </a>
                                  }/>
                </ListItem>
            )
        });

        return (
            <List>
                <ListItem className={importedStyles.header}>
                    <ListItemIcon children={<span/>}/>
                    <h1>
                        Contactos de emergencia
                    </h1>
                </ListItem>
                {contacts}
            </List>
        );
    }
    renderContent() {
        return (
            <React.Fragment>
                <div className={importedStyles.basicData}>
                    {this.getBasicData()}
                </div>
                <div className={importedStyles.medicData}>
                    {this.getMedicData()}
                </div>
                <div className={importedStyles.emergencyData}>
                    {this.getEmergencyData()}
                </div>
                {this.state.emergencySteps && this.state.emergencySteps.length > 0 &&
                <div>
                    <ListItem className={importedStyles.header}>
                        <ListItemIcon children={<span/>}/>
                        <h1>
                            Pasos de emergencia
                        </h1>
                    </ListItem>
                    {this.renderEmergencySteps()}
                </div>
                }
            </React.Fragment>
        );
    }

    handleAssignModal = () => {
        this.setState({assignModal: !this.state.assignModal});
    };

    handlePhotosModal = (photos) => {
        if (photos) {
            this.setState({photosModal: !this.state.photosModal, photos: photos});
        } else {
            this.setState({photosModal: !this.state.photosModal});
        }
    };

    renderEmergencyStepsModalContent = () => {
        const steps = this.state.emergencySteps;
        let listItems = [];

        Object.keys(steps).forEach((key) => {
            let step = steps[key];
            listItems.push(
                <ListItem>
                    <ListItemIcon>
                        {step.position + 1}
                    </ListItemIcon>
                    <ListItemText primary={step.text}/>
                </ListItem>
            );
        });

        return (
            <List component="nav">
                {listItems}
            </List>
        );
    };
    renderEmergencySteps = () => {
        const steps = this.state.emergencySteps;
        let listItems = [];

        Object.keys(steps).forEach((key) => {
            let step = steps[key];
            listItems.push(
                <ListItem>
                    <ListItemIcon>
                        {step.position + 1}
                    </ListItemIcon>
                    <ListItemText primary={step.text}/>
                </ListItem>
            );
        });

        return (
            <List>
                {listItems}
            </List>
        );
    };

    renderNavBar = () =>{

        //Render navbar only if you're logged into app
        if (this.props.logged) {
            return (
                <Navbar path={window.location.pathname}/>
            )
        }else{
            return(<React.Fragment/>);
        }
    };

    sendScanNotification = () => {
        const uuidBracelet = this.props.match.params.id;
        fetch(baseRoute + 'api/patients/sendScanNotification', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidBracelet: uuidBracelet,
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (response.error) {
                throw new Error(response.errorMessage)
            }
        }).catch((error) => {
            console.error('Error: ', error);
        });

    };

    handleSnackClose = () => {
        let noNotify = this.props.match.params.noNotify ? true : false;

        if (!this.props.view && !noNotify){
            this.sendScanNotification();
        } else{
            this.props.dispatch(handle_view_detail(false));
        }
       this.setState({showSnack: false})
    };

    render() {
        let content = <div className={this.props.logged ? importedStyles.content : importedStyles.noNavBarContent}>{this.renderContent()}</div>;
        if (this.state.error) {
            content = <div className={importedStyles.errorMessage}>
                <span>Ha ocurrido un error, vuelve a escanear el código </span>
            </div>;
        } else if (this.state.errorPatient) {
            content = <div className={importedStyles.errorMessage}>
                <span>Esta pulsera aún no contiene datos o no están visibles</span>
            </div>;
        }

        return (
            <div className={importedStyles.detail}>
                <div className={importedStyles.detailBox}>
                    {this.state.gettingData ?
                        <div className={importedStyles.chargingContent}>
                            <CircularProgress size={65}/>
                        </div>
                        : content}
                    <AssignModal open={this.state.assignModal} handleModal={() => this.handleAssignModal()}
                                 braceletUuid={this.props.match.params.id}/>
                    <PatientModal/>
                    <EmergencyStepsModal/>

                    <PhotosDialog open={this.state.photosModal} handleModal={() => this.handlePhotosModal()}
                                  photos={this.state.photos}/>
                    {this.props.logged && <Navbar path={window.location.pathname}/>}
                </div>
                <Snackbar
                    className={importedStyles.snack}
                    message={
                        <span className={importedStyles.snackMessage}>
                            <Warning className={importedStyles.snackIcon}/>
                           Los datos de esta ficha han sido facilitados por el usuario. La empresa propietaria no se hace responsable de su veracidad.
                        </span>
                    }
                    open={this.state.showSnack}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    onClose={this.handleSnackClose}
                    action={
                        <Button
                            key="close"
                            aria-label="Close"
                            className={importedStyles.snackButton}
                            variant={"outlined"}
                            onClick={this.handleSnackClose}
                        >
                            Aceptar
                        </Button>
                    }
                />

                <Dialog
                    fullScreen={true}
                    open={this.state.showEmergencyStepsModal}
                    onClose={() => this.setState({showEmergencyStepsModal: false})}
                    disableBackdropClick
                    fullWidth={true}
                    //maxWidth={"sm"}
                >
                    <DialogTitle className={importedStyles.dialogTitle}>
                        {"Pasos a seguir en caso de emergencia"}
                    </DialogTitle>
                    <DialogContent className={importedStyles.stepsContainer}>
                        <Grid container spacing={8}>
                            {this.renderEmergencyStepsModalContent()}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {this.state.hasPatientData &&
                        <Button variant={"contained"} fullWidth size={"large"} className={importedStyles.seeDataButton}
                                onClick={() => this.setState({showEmergencyStepsModal: false})}>
                            Ver datos
                        </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({braceletListReducer, authReducer}) => {
    return ({
        view: braceletListReducer.viewDetail,
        logged: authReducer.logged,
    });
};
export default connect(mapStateToProps)(Detail);