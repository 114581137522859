import importedStyles from "./BraceletCard.module.sass";
import React from 'react';
import QRCode from 'qrcode.react'
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
    AccountCircle,
    Add,
    Comment,
    ContactPhone,
    CreditCard,
    ExpandLess,
    ExpandMore,
    LocalHospital,
    LocalPharmacy,
    NotInterested,
    Place,
    Visibility,
} from '@material-ui/icons';
import {
    clean_patient_data,
    patient_emergency_steps_handle_modal,
    patient_handle_modal,
    update_basic_data,
    update_bracelet_uuid,
    update_contact_data,
    update_documents_data,
    update_editing,
    update_emergency_steps,
    update_medic_data
} from "../../Patient/PatientActions";
import {connect} from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import history from "../../../helpers/history";
import Grid from "@material-ui/core/Grid/Grid";
import List from "@material-ui/core/List/List";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Button from "@material-ui/core/Button/Button";
import PhotosDialog from "../../../components/PhotosDialog/PhotosDialog";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import {showSnack, snackTypes} from "../../../components/Snack/SnackActions";
import baseRoute from "../../../helpers/baseRoute";
import {handle_view_detail, update_list} from "../BraceletListActions";
import Tooltip from "@material-ui/core/Tooltip";
import stepIcon from '../../../img/edit-steps-grey.png';
import editPatientIcon from '../../../img/edit-patient.png';
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Image from 'react-image-webp';
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPixel from "react-facebook-pixel";


class BraceletCard extends React.Component {

    state = {
        expanded: false,
        photosModal: false,
        photos: [],
        disabled: false,
        sendingData: false,
        eraseModal: false,
        hardResetModal: false,
    };

    constructor(props) {
        super(props);
        this.assignPatient = this.assignPatient.bind(this);
    }

    componentDidMount() {
        this.setState({disabled: this.props.bracelet.disabled});
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({disabled: nextProps.bracelet.disabled});
    }

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };
    handleEraseModal = () => {
        this.setState(state => ({eraseModal: !state.eraseModal}));
    };
    handleHardResetModal = () => {
        this.setState(state => ({hardResetModal: !state.hardResetModal}));
    };

    getBasicDataCard(patient) {
        return (
            <Grid item xs={12} md={4}>
                <List
                    component="nav"
                    subheader={<ListSubheader className={importedStyles.subheaderText}>Datos básicos</ListSubheader>}
                >
                    <div className={importedStyles.paddedList}>
                        <ListItem>
                            <ListItemIcon>
                                <AccountCircle className={importedStyles.ListIcon}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={patient.name + ' ' + patient.surname}
                                secondary={patient.age + ' años'}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Place className={importedStyles.ListIcon}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={patient.address.city + ', ' + patient.address.region + ', ' + patient.address.country}/>
                        </ListItem>
                        {this.getDocumentsCard(patient.documents)}
                    </div>
                </List>
            </Grid>
        );
    }

    handlePhotosModal = (photos) => {
        if (photos) {
            this.setState({photosModal: !this.state.photosModal, photos: photos});
        } else {
            this.setState({photosModal: !this.state.photosModal});
        }
    };

    getDocumentsCard(documents) {
        let documentsView = [];
        Object.keys(documents).forEach((doc) => {
            documentsView.push(
                <ListItem key={doc}>
                    <ListItemIcon>
                        <CreditCard className={importedStyles.ListIcon}/>
                    </ListItemIcon>
                    <ListItemText
                        primary={documents[doc].number}
                        secondary={
                            <div className={importedStyles.documentSecondaryBox}>
                                <span className={importedStyles.documentTypeText}>{documents[doc].type}</span>
                                <span className={importedStyles.documentViewPhotos}
                                      onClick={() => this.handlePhotosModal(documents[doc].photos.split(', '))}
                                      style={documents[doc].photos ? {} : {visibility: 'hidden'}}
                                >
                                    Ver imagen/es
                                </span>
                            </div>
                        }
                    />
                </ListItem>
            );
        });
        return documentsView;
    }

    getMedicDataCard(patient) {

        return (
            <Grid item xs={12} md={4}>
                <List
                    component="nav"
                    subheader={<ListSubheader
                        className={importedStyles.subheaderText}><span>Datos médicos</span></ListSubheader>}
                >
                    <div className={importedStyles.paddedList}>
                        <ListItem>
                            <ListItemIcon>
                                <LocalHospital className={importedStyles.ListIcon}/>
                            </ListItemIcon>
                            <ListItemText secondary={'Diagnóstico'}
                                          primary={patient.diagnosis ? patient.diagnosis : '---'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <NotInterested className={importedStyles.ListIcon}/>
                            </ListItemIcon>
                            <ListItemText secondary={'Alergias'}
                                          primary={patient.allergies ? patient.allergies : '---'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <LocalPharmacy className={importedStyles.ListIcon}/>
                            </ListItemIcon>
                            <ListItemText secondary={'Tratamiento'}
                                          primary={patient.medicines ? patient.medicines : '---'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Comment className={importedStyles.ListIcon}/>
                            </ListItemIcon>
                            <ListItemText secondary={'Otros datos'}
                                          primary={patient.observation ? patient.observation : '---'}/>
                        </ListItem>
                    </div>
                </List>
            </Grid>
        );
    }

    getContactDataCard(patient) {
        let contacts = [];
        Object.keys(patient.emergency_contacts).forEach((contact) => {

            let primaryText = '';
            if (patient.emergency_contacts[contact].relationship) {
                primaryText = <span>{patient.emergency_contacts[contact].name}
                    <i className={importedStyles.relationshipText}>
                            ({patient.emergency_contacts[contact].relationship})
                        </i>
                    </span>
            } else {
                primaryText = patient.emergency_contacts[contact].name;
            }


            contacts.push(
                <ListItem key={contact}>
                    <ListItemIcon>
                        <ContactPhone className={importedStyles.ListIcon}/>
                    </ListItemIcon>
                    <ListItemText primary={primaryText}
                                  secondary={'+' + patient.emergency_contacts[contact].prefix + ' ' + patient.emergency_contacts[contact].phone}/>
                </ListItem>
            )
        });

        return (
            <Grid item xs={12} md={4} className={importedStyles.contactDataContainer}>
                <List
                    component="nav"
                    subheader={<ListSubheader className={importedStyles.subheaderText}>Contactos de
                        emergencia</ListSubheader>}
                >
                    <div className={importedStyles.paddedList}>
                        {contacts}
                    </div>
                </List>
            </Grid>
        );
    }

    handleDisableBracelet(uuidBracelet) {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/bracelets/disableBracelet', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidBracelet: uuidBracelet,
                    uuidUser: this.props.user.uuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.props.dispatch(showSnack('Estado actualizado con éxito', snackTypes.success));
                this.setState({disabled: !this.state.disabled});
            } else {
                throw new Error('error');
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error verificando los datos, por favor, vuelve a intentarlo dentro de unos minutos', snackTypes.error));
        });

    }

    handleEnableBracelet(uuidBracelet) {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/bracelets/enableBracelet', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    uuidBracelet: uuidBracelet,
                    uuidUser: this.props.user.uuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            if (!response.error) {
                this.props.dispatch(showSnack('Estado actualizado con éxito', snackTypes.success));
                this.setState({disabled: !this.state.disabled});
            } else {
                throw new Error('error');
            }
            this.setState({sendingData: false});
        }).catch((error) => {
            console.log(error);
            this.setState({sendingData: false});
            this.props.dispatch(showSnack('Ha ocurrido un error verificando los datos, por favor, vuelve a intentarlo dentro de unos minutos', snackTypes.error));
        });

    }

    renderEmergencySteps = () => {
        const steps = this.props.bracelet.emergency_steps;
        let listItems = [];

        if (steps.length > 0) {
            Object.keys(steps).forEach((key) => {
                let step = steps[key];
                listItems.push(
                    <ListItem>
                        <ListItemIcon>
                            {step.position + 1}
                        </ListItemIcon>
                        <ListItemText primary={step.text}/>
                    </ListItem>
                );
            });
        } else {
            listItems.push(
                <ListItem>
                    <Button
                        className={importedStyles.addButton}
                        variant={'contained'}
                        fullWidth
                        color={"primary"}
                        onClick={() => this.editEmergencyStepsHandler()}
                    >
                        <Add className={importedStyles.addButtonIcon}/> Añadir pasos de emergencia
                    </Button>
                </ListItem>
            );
        }

        return (
            <List
                component="nav"
                subheader={<ListSubheader className={importedStyles.subheaderText}>Pasos de emergencia</ListSubheader>}
            >
                {listItems}
            </List>
        );
    };

    renderPatientData(bracelet) {
        if (bracelet.patientData) {
           return (
                <React.Fragment>
                    {this.getBasicDataCard(bracelet.patientData)}
                    {this.getMedicDataCard(bracelet.patientData)}
                    {this.getContactDataCard(bracelet.patientData)}
                </React.Fragment>
            );
        } else {
            return (
                <Grid item xs={12}>
                    <List
                        component="nav"
                        subheader={<ListSubheader className={importedStyles.subheaderText}>Datos del
                            paciente</ListSubheader>}
                    >
                        <ListItem>
                            <Button
                                className={importedStyles.addButton}
                                variant={'contained'}
                                fullWidth
                                color={"primary"}
                                onClick={this.editHandler}
                            >
                                <Add className={importedStyles.addButtonIcon}/> Añadir datos del paciente
                            </Button>
                        </ListItem>
                    </List>
                </Grid>
            );
        }
    }

    handleErasePatientData = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/bracelets/eraseBraceletsPatientData', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: this.props.user.uuid,
                    selectedBracelets: [this.props.bracelet.uuid]
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);

            if (!response.error) {
                this.setState({
                    sendingData: false,
                    eraseModal: false,
                });
                this.props.dispatch(update_list(response.bracelets));
                this.props.dispatch(showSnack('Datos eliminados con éxito'), 'success');
            } else {
                throw new Error('Error al eliminar los datos: ', response.errorMessage);
            }

        }).catch((error) => {
            this.props.dispatch(showSnack('No se han podido eliminar los datos, prueba de nuevo en unos minutos'), 'warning');
            this.setState({
                sendingData: false,
                eraseModal: false
            });
        });
    };
    handleHardReset = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/bracelets/hardReset', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: this.props.user.uuid,
                    braceletUuid: this.props.bracelet.uuid
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);

            if (!response.error) {
                this.setState({
                    sendingData: false,
                    hardResetModal: false,
                });
                this.props.dispatch(update_list(response.bracelets));
                this.props.dispatch(showSnack('Pulsera reiniciada con éxito'), 'success');
            } else {
                throw new Error('Error al eliminar los datos y desvincular: ', response.errorMessage);
            }

        }).catch((error) => {
            this.props.dispatch(showSnack('No se han podido eliminar y desvincular los datos, prueba de nuevo en unos minutos'), 'warning');
            this.setState({
                sendingData: false,
                hardResetModal: false
            });
        });
    };

    renderCardContent(bracelet) {

        return (
            <React.Fragment>
                <Grid container className={importedStyles.gridStepsContainer} spacing={8}>
                    <Grid item xs={12}>
                        {this.renderEmergencySteps()}
                    </Grid>
                </Grid>
                <Grid container className={importedStyles.gridContainer} spacing={8}>
                    {this.renderPatientData(bracelet)}
                </Grid>
                <Grid item xs={12} className={importedStyles.braceletStateActions}>
                    <div className={importedStyles.deleteButtons}>
                        {/*<Button variant={"outlined"} size={"medium"} onClick={this.handleHardResetModal}
                                disabled={this.state.sendingData}
                                className={importedStyles.erasePatientDataButton}>
                            Desvincular y borrar datos
                        </Button>*/}
                        <Button variant={"outlined"} size={"medium"} onClick={this.handleEraseModal}
                                disabled={this.state.sendingData}
                                className={importedStyles.erasePatientDataButton}>
                            Borrar datos
                        </Button>
                    </div>
                    <FormControlLabel
                        disabled={this.state.sendingData}
                        className={importedStyles.contactDataEnable}
                        label={this.state.disabled ? 'Deshabilitada' : 'Habilitada'}
                        control={
                            <Switch
                                checked={!this.state.disabled}
                                classes={{
                                    switchBase: '#cdcdcd',
                                }}
                                onChange={() => this.state.disabled ? this.handleEnableBracelet(bracelet.uuid) : this.handleDisableBracelet(bracelet.uuid)}
                            />
                        }
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderHiddenQr = (qrUuid) => {
        //Render the QR with big size but hidden to user view, just for be able to download later
        return (
            <QRCode
                id={'canva' + qrUuid}
                size={400}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"Q"}
                style={{display: 'none'}}
                value={'https://mipulsid.com/users/' + qrUuid}
            />
        );
    };

    downloadQr = (qrUuid) => () => {
        let link = document.createElement('a');
        let canvas = document.getElementById('canva' + qrUuid);
        link.href = canvas.toDataURL();
        link.download = "Qr-PulsID-" + qrUuid + ".png";
        document.body.appendChild(link);
        link.click();
    };

    renderAvatar(bracelet) {
        if (bracelet.patientUuid || (bracelet.emergency_steps && bracelet.emergency_steps.length > 0)) {
            return (
                <React.Fragment>
                    <div className={importedStyles.avatarContainer} onClick={() => {
                        this.props.dispatch(handle_view_detail(true));
                        history.push('/users/' + bracelet.uuid)
                    }}>
                        <QRCode
                            className={importedStyles.qrImage}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"M"}
                            value={'https://mipulsid.com/users/' + bracelet.uuid}
                           /* imageSettings={{
                                src: "https://mipulsid.com/logo_celeste.png",
                                x: null,
                                y: null,
                                height: 80,
                                width: 80,
                                excavate: true,
                            }}*/
                        />
                        {this.renderHiddenQr(bracelet.uuid)}
                        <Visibility className={importedStyles.visibilityIcon}/>
                    </div>
                    <IconButton className={importedStyles.downloadQrButton} onClick={this.downloadQr(bracelet.uuid)}>
                        <DownloadIcon style={{fontSize: '1.25rem'}}/>
                    </IconButton>
                </React.Fragment>
            );
        } else {
            return (
                <div className={importedStyles.avatarBraceletContainer}>
                    <Image
                        className={importedStyles.logoImg}
                        src={require('../../../img/pulsidBracelet.png')}
                        webp={require('../../../img/pulsidBracelet.webp')}
                        alt={'PulsID'}
                    />
                </div>
            );
        }
    }

    assignPatient = () => {
        this.props.dispatch(clean_patient_data());
        this.props.dispatch(patient_handle_modal());
        this.props.dispatch(update_bracelet_uuid(this.props.bracelet.uuid));
    };

    assignEmergencySteps = () => {
        ReactPixel.trackCustom('firstEmergencySteps');

        this.props.dispatch(update_bracelet_uuid(this.props.bracelet.uuid));
        this.props.dispatch(update_emergency_steps(this.props.bracelet.emergency_steps));
        this.props.dispatch(patient_emergency_steps_handle_modal());
    };

    editEmergencyStepsHandler = () => {
        ReactPixel.trackCustom('editingEmergencySteps');

        //Update bracelet uuid
        this.props.dispatch(update_bracelet_uuid(this.props.bracelet.uuid));
        //Update redux bracelet emergency steps
        this.props.dispatch(update_emergency_steps(this.props.bracelet.emergency_steps));
        //Open modal
        this.props.dispatch(patient_emergency_steps_handle_modal());
    };

    editHandler = () => {
        ReactPixel.trackCustom('editingPatientData');
        const patientData = this.props.bracelet.patientData;

        //Update redux patient basic data
        this.props.dispatch(update_editing(true));

        //Update redux patient basic data
        if (patientData) {
            const addressData = this.props.bracelet.patientData.address;
            this.props.dispatch(update_basic_data(patientData.name, patientData.surname, patientData.bornDate, patientData.photo_url,
                addressData.country, addressData.city, addressData.region, addressData.address, addressData.zipCode));
            //Update redux patient documents
            this.props.dispatch(update_documents_data(patientData.documents));

            //Update redux patient emergency contacts
            this.props.dispatch(update_contact_data(patientData.emergency_contacts));

            //Update redux patient medical data (check data to convert null values into empty string to avoid split errors)
            let patientDiagnosis = patientData.diagnosis ? patientData.diagnosis.split(',') : null;
            let patientMedicines = patientData.medicines ? patientData.medicines.split(',') : null;
            let patientAllergies = patientData.allergies ? patientData.allergies.split(',') : null;
            this.props.dispatch(update_medic_data(patientDiagnosis, patientMedicines, patientAllergies, patientData.observation));
        }

        //Open modal
        this.props.dispatch(patient_handle_modal());

        //Update bracelet uuid (this opens the edit modal)
        this.props.dispatch(update_bracelet_uuid(this.props.bracelet.uuid));

    };

    renderCardTitle = (bracelet) => {
        if (bracelet.uuid) {
            if (bracelet.patientUuid || bracelet.emergency_steps.length > 0) {
                if (bracelet.patientData) {
                    return bracelet.patientData.name;
                } else if (bracelet.emergency_steps.length > 0) {
                    return '¡Ya tienes los pasos, ahora solo falta completar tus datos para sentirte mucho más protegido!';
                }
            } else {
                return 'Ya casi está, solo falta completar tus datos o añadir los pasos a seguir en caso de emergencia';
            }

        } else {
            return 'Pulsera sin asignar, ¡escaneá tu pulsera cuando la recibas!';
        }
    };

    render() {
        const bracelet = this.props.bracelet;
        const emergencySteps = bracelet.emergency_steps;
        const hasEmergencySteps = bracelet.emergency_steps && bracelet.emergency_steps.length > 0;
        let data = null;

        if (bracelet.uuid) {
            if (bracelet.patientUuid) {
                data =
                    <React.Fragment>
                        <CardActions className={importedStyles.actions} disableActionSpacing>
                            <Tooltip disableFocusListener disableTouchListener title="Simular escaneo" placement="top">
                                <IconButton
                                    onClick={() => {
                                        this.props.dispatch(handle_view_detail(true));
                                        history.push('/users/' + bracelet.uuid);
                                    }}
                                >
                                    <Visibility className={importedStyles.visibilityIcon}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableFocusListener disableTouchListener title="Editar datos" placement="top">
                                <IconButton onClick={() => this.editHandler()}>
                                    {/*<Edit/>*/}
                                    <img alt={'editPatientData'} src={editPatientIcon}
                                         className={importedStyles.customIcon}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableFocusListener disableTouchListener title="Editar pasos de emergencia"
                                     placement="top">
                                <IconButton onClick={this.editEmergencyStepsHandler}>
                                    <img alt={'editSteps'} src={stepIcon} className={importedStyles.customIcon}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                disableFocusListener disableTouchListener
                                title={this.state.expanded ? 'Ocultar detalle' : 'Desplegar detalle'}
                                placement="top"
                            >
                                <IconButton
                                    onClick={this.handleExpandClick}
                                    aria-expanded={this.state.expanded}
                                    aria-label="Ver más..."
                                >
                                    {this.state.expanded ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <CardContent style={{flex: 1, flexDirection: 'column'}}>
                                {this.renderCardContent(bracelet)}
                            </CardContent>
                        </Collapse>
                    </React.Fragment>;
            } else {
                data = <div className={importedStyles.assignContainer}>
                    <Button className={importedStyles.assignButton} onClick={this.assignPatient}>
                        Completar datos
                    </Button>
                    {emergencySteps && emergencySteps.length > 0 ?
                        <Tooltip disableFocusListener disableTouchListener title="Editar pasos de emergencia"
                                 placement="top">
                            <Button className={importedStyles.assignButton} onClick={this.assignEmergencySteps}
                                    style={{marginLeft: 10}}>
                                Editar pasos
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip disableFocusListener disableTouchListener title="Añadir pasos de emergencia"
                                 placement="top">
                            <Button className={importedStyles.assignButton} onClick={this.assignEmergencySteps}
                                    style={{marginLeft: 10}}>
                                Añadir pasos
                            </Button>
                        </Tooltip>
                    }
                </div>;
            }
        }

        return (
            <li className={importedStyles.braceletCard}>
                {this.props.selecting &&
                (bracelet.patient || hasEmergencySteps ?
                    <Checkbox
                        checked={this.props.checked}
                        value={bracelet.uuid}
                        onChange={this.props.onChangeCheck}
                        color={"default"}
                    />
                    :
                    <Checkbox style={{visibility: 'hidden'}}/>)
                }
                <Card className={importedStyles.card} key={this.props.documentId}>
                    <div className={importedStyles.container}>
                        <CardHeader
                            avatar={this.renderAvatar(bracelet)}
                            classes={{
                                avatar: importedStyles.avatar,
                                root: importedStyles.cardHeader,
                                title: importedStyles.cardHeaderTitle,
                                subheader: importedStyles.subheaderCardText
                            }}
                            title={this.renderCardTitle(bracelet)}
                            subheader={bracelet.patientData ? bracelet.patientData.surname : ''}
                        />
                        {data}
                    </div>
                </Card>
                <PhotosDialog open={this.state.photosModal} handleModal={() => this.handlePhotosModal()}
                              photos={this.state.photos}/>
                <Dialog
                    open={this.state.eraseModal}
                    onClose={this.handleEraseModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        Borrar datos
                        <IconButton onClick={this.handleEraseModal} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{textAlign: 'center', color: '#aa181d'}}>
                            Si eliminas los datos de esta pulsera no podrás recuperarlos más tarde.
                            ¿Deseas continuar con el borrado?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleEraseModal} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleErasePatientData} color="primary" variant={"contained"}
                                disabled={this.state.sendingData} autoFocus>
                            Borrar {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.hardResetModal}
                    onClose={this.handleHardResetModal}
                    disableBackdropClick
                >
                    <DialogTitle>
                        Borrar datos y desvincular pulsera
                        <IconButton onClick={this.handleHardResetModal} className={importedStyles.closeModalIcon}>
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{textAlign: 'center', color: '#c31a1f'}}>
                            Si continuas, se borrarán los datos de la pulsera y además dejará de estar vinculada a tu cuenta, es decir, volvera al mismo estado "limpio" de cuando la recibiste
                            <h3 style={{color: 'rgb(170,23,28)', fontWeight: 500}}>¿Deseas continuar con este proceso? <br/>Recuerda que NO es reversible.</h3>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleHardResetModal} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleHardReset} color="primary" variant={"contained"}
                                disabled={this.state.sendingData} autoFocus>
                            Borrar {this.state.sendingData &&
                        <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </li>
        );
    }
}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(withMobileDialog()(BraceletCard));