import React from 'react';
import importedStyles from "./WeAreEco.module.sass";
import history from '../../helpers/history';
import Snack from "../../components/Snack/Snack";
import ReactGA from 'react-ga';
import Navbar from "../../components/Navbar/Navbar";
import connect from "react-redux/es/connect/connect";
import Image from 'react-image-webp';
import {Helmet} from "react-helmet";
import Cookies from "universal-cookie";
import ReactPixel from "react-facebook-pixel";
import 'video.js/dist/video-js.css';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TapIcon from '@material-ui/icons/TouchApp';


const cookies = new Cookies();
const mobile = window.matchMedia("(max-width: 700px)");

class WeAreEco extends React.Component {

    state = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactPixel.pageView();
        ReactGA.pageview('/compromisoEco');
    }

    componentWillUnmount() {
    }

    getCTA(CTAStyle, bagdeStyle, copy) {
        return (

            <div className={importedStyles.CTAContainer}>
                <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'} target={'_blank'}
                   style={{textDecoration: 'none'}}>
                    <Button className={[CTAStyle, 'primaryCTA']}>
                        {copy}
                        <TapIcon className={importedStyles.tapIcon}/>
                        {/*<div className={importedStyles.discountSticker}>-20%</div>*/}
                    </Button>
                </a>
            </div>

        );
    }


//////////// HANDLERS ////////////////
    handleBuyModal = () => {
        ReactPixel.trackCustom('buyModalOpen', {description: 'El usuario ha empezado el flujo de compra'});
        ReactGA.modalview('/buyModalOpened');
        history.push('/?shop=true');
    };

//////////////////////////////

    render() {
        return (
            <div className={importedStyles.Landing}>
                <Navbar path={window.location.pathname} hidden={true}/>
                <Helmet>
                    <title>PulsID</title>
                    <meta name="description"
                          content="Protege a tus seres queridos con PulsID, nuestra pulsera contra emergencias"/>
                    <meta name="keywords"
                          content="pulsid, pulsera, emergencia, proteger, alzheimer, epilepsia, qr ,hospital, residencia"/>
                </Helmet>
                <Grid container id={'history'} className={importedStyles.historyContainer}>
                    <Grid item xs={12} md={6} className={importedStyles.historyImageContainer}>
                        <Image
                            src={require('../../img/eco.png')}
                            webp={require('../../img/eco.webp')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} className={importedStyles.historyContainerContent}>
                        <h1>COMPROMISO MEDIOAMBIENTAL</h1>
                        <h3>En 2050 se prevé que habrá más plásticos que peces en el océano.</h3>
                        <p>En los últimos 50 años la producción mundial de plástico ha pasado
                            de 1,5 millones de toneladas al año a 245 toneladas.
                            Una gran parte de estas toneladas van a parar a nuestros mares y
                            océanos, convirtiéndolos en los mayores vertederos de plásticos y
                            causando daños irreparables a las especies marinas que habitan en
                            ellos.
                            En PulsID somos conscientes de esta problemática y, por ello, en
                            nuestro compromiso con el medio ambiente <b>decidimos utilizar un packaging de cartón
                                eco-friendly </b> y, así, evitar crear
                            innecesariamente más de 2000 kg de plástico al año.</p>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {this.getCTA(importedStyles.primaryCTAButton, importedStyles.pulsidPriceBadgeDesktop, 'Consíguela ya')}
                    </Grid>
                </Grid>

                <div className={importedStyles.footerContainer}>
                    <div className={importedStyles.footerContent}>
                        <span style={{textAlign: 'center'}}> Si tienes alguna duda o deseas más información contactanos en <br/>
                        <a target={'_blank'} href={'mailto:hola@gmail.com'}>hola@mipulsid.com</a>
                        </span>
                        <a className={importedStyles.sevi} href={'https://sevisl.com/'}>Copyright © Sociedad Europea de
                            Verificación de Identidad 2020</a>
                        <br/>
                        <div className={importedStyles.footerLawsContainer}>
                            <p className={importedStyles.privacy} onClick={() => history.push('/law')}>Aviso legal</p>
                            <p> y </p>
                            <p className={importedStyles.privacy} onClick={() => history.push('/privacy')}>política de
                                privacidad</p>
                        </div>
                        <Image
                            className={importedStyles.seviLogo}
                            src={require('../../img/logoBrandBlue.png')}
                            webp={require('../../img/logoBrandBlue.webp')}
                            alt={'SEVI'}
                        />
                        <div className={importedStyles.footerLogosContainer}>
                            <Image
                                style={{width: 75}}
                                src={require('../../img/enisa.png')}
                                webp={require('../../img/enisa.webp')}
                            />
                            <Image
                                style={{width: 125}}
                                src={require('../../img/logoIvace.png')}
                                webp={require('../../img/logoIvace.webp')}
                            />
                        </div>
                    </div>
                </div>
                <div className={importedStyles.finalImgContainer}>
                    <Image
                        src={require('../../img/fondoolas2.png')}
                        webp={require('../../img/fondoolas2.webp')}
                    />
                </div>
                <Snack/>
            </div>
        );
    }
}

const mapStateToProps = ({authReducer, braceletListReducer}) => {
    return ({
        user: authReducer.user,
    });
};
export default connect(mapStateToProps)(WeAreEco);