import connect from "react-redux/es/connect/connect";
import baseRoute from "../../helpers/baseRoute";
import importedStyles from "./BraceletList.module.sass";
import React from 'react';
import BraceletCard from "./BraceletCard/BraceletCard";
import PasswordChangeModal from "../PasswordChangeModal/PasswordChangeModal";
import AuthModals from "../Modals/BuyModals";
import PatientModal from "../Patient/PatientModal";
import Button from "@material-ui/core/Button/Button";
import CloseIcon from '@material-ui/icons/Close';
import ActiveIcon from '@material-ui/icons/PowerSettingsNew';
import ShopIcon from '@material-ui/icons/ShoppingCart';
import Close from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlined';
import {add_bracelet, update_list} from "./BraceletListActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import history from "../../helpers/history";
import Navbar from "../../components/Navbar/Navbar";
import {showSnack, snackTypes} from "../../components/Snack/SnackActions";
import * as qs from 'query-string';
import * as ReactGA from "react-ga";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import Typography from "@material-ui/core/Typography/Typography";
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {show_buy_modal} from "../Modals/ModalsActions";
import IconButton from "@material-ui/core/IconButton";
import EmergencyStepsModal from "../Patient/EmergencyStepsModal/EmergencyStepsModal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Grid} from "@material-ui/core";
import FacebookLogo from "../../img/icons/facebookLogo.png";
import GoogleIcon from "../../img/icons/googleLogo.svg";

class BraceletList extends React.Component {

    state = {
        openChangePasswordModal: false,
        error: true,
        paymentSuccessAndShareModal: false,
        braceletList: [
            {
                assigned: 0,
                patientData: null,
                size: 'S',
                color: 'Orange',
            },
            {
                assigned: 1,
                patientData: {
                    name: 'Maria',
                    surname: 'Lluch',
                    age: 16,
                    allergies: 'Penicilina',
                    medicines: 'Pastillas ',
                    diagnosis: 'Epilepsia',
                    observation: 'Grupo sanguíneo A+',

                    address: {
                        city: 'Cariñena',
                        region: 'Zaragoza',
                        country: 'España'
                    },
                    documents: {
                        document1: {
                            type: 'DNI',
                            number: '54001200X'
                        },
                        document2: {
                            type: 'SIP',
                            number: 'e2b32001200X'
                        }
                    },
                    emergency_contacts: {
                        contact1: {
                            name: 'Diego',
                            phone: '612332112'
                        },
                        contact2: {
                            name: 'Isabel',
                            phone: '61212154'
                        }
                    },
                },
                size: 'M',
                color: 'Orange',
            }
        ],
        user: {},
        selectedBracelets: [],
        selectableBracelets: false,
        deleteAllPatientDataModal: false,
        selecting: false,
    };

    constructor(props) {
        super(props);
        this.handleNewBracelet = this.handleNewBracelet.bind(this);
    }

    renderRatingsSocialsNetworks = () => {
        return(
            <Grid container spacing={4} className={importedStyles.rateUsContainer}>
                <Grid item xs={12} className={importedStyles.rateUsCopy}>
                    <Typography><h2>¿Ya formas parte de nuestros #UsuariosProtegidos?</h2></Typography>
                    <Typography><h2>¡Queremos saber tu opinión!</h2></Typography>
                </Grid>
                <Grid item xs={4} md={2} className={importedStyles.rateIconItem}>
                    <IconButton className={importedStyles.rateIconButton} href={'https://www.facebook.com/pg/mipulsid/reviews/'} target={'_blank'}>
                        <img className={importedStyles.rateIcon}  src={FacebookLogo} alt={'facebook icon'}/>
                    </IconButton>
                </Grid>
                <Grid item xs={4} md={2} className={importedStyles.rateIconItem}>
                    <IconButton  className={importedStyles.rateIconButton}  href={'https://g.page/pulsid/review?mt'} target={'_blank'}>
                        <img className={importedStyles.rateIcon} src={GoogleIcon} alt={'google icon'} />
                    </IconButton>
                </Grid>
            </Grid>
        )
    };

    componentDidMount() {
        const params = qs.parse(this.props.location.search);

        if (typeof params.paymentSuccess !== 'undefined') {
            if (params.paymentSuccess === 'false') {
                this.props.dispatch(showSnack('Oops, parece que ha ocurrido algún error', snackTypes.error));
            } else {
                this.setState({paymentSuccessAndShareModal: true});
                this.props.dispatch(showSnack('Pedido realizado con éxito, pronto recibirá su pulsera', snackTypes.success));
            }
        }

        if (this.props.user && this.props.user.uuid) {
            if (this.props.user.oldPassword) {
                this.handleChangePasswordModal();
            }
            this.setState({sendingData: true});
            fetch(baseRoute + 'api/users/getUserBracelets', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        userUuid: this.props.user.uuid
                    }),
                }
            ).then((responseJSON) => {
                return responseJSON.text();
            }).then((response) => {
                response = JSON.parse(response);
                this.setState({
                    error: false,
                    sendingData: false
                });
                this.props.dispatch(update_list(response.bracelets));
            }).catch((error) => {
                this.setState({
                    error: true,
                    sendingData: false
                });
            });
        } else {
            history.push('/');
        }
    }

    handleChangePasswordModal = () => {
        this.setState({openChangePasswordModal: !this.state.openChangePasswordModal});
    };

    handleAuthModal = () => {
        //this.setState({openAuthModal: !this.state.openAuthModal});
        this.props.dispatch(show_buy_modal(!this.props.showBuyModal));
        if (this.props.showBuyModal) {
            ReactGA.modalview('/buyModalOpenedSecondBracelet');
        }
    };

    handleDeleteAllPatientDataModal = () => {
        this.setState({deleteAllPatientDataModal: !this.state.deleteAllPatientDataModal});
    };

    handleToggle = value => () => {
        const {selectedBracelets} = this.state;
        const currentIndex = selectedBracelets.indexOf(value);
        const newSelectedBracelets = [...selectedBracelets];

        if (currentIndex === -1) {
            newSelectedBracelets.push(value);
        } else {
            newSelectedBracelets.splice(currentIndex, 1);
        }

        this.setState({
            selectedBracelets: newSelectedBracelets,
        });
    };

    handleErasePatientData = () => {
        this.setState({sendingData: true});
        fetch(baseRoute + 'api/bracelets/eraseBraceletsPatientData', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userUuid: this.props.user.uuid,
                    selectedBracelets: this.state.selectedBracelets
                }),
            }
        ).then((responseJSON) => {
            return responseJSON.text();
        }).then((response) => {
            response = JSON.parse(response);
            this.setState({
                error: false,
                sendingData: false,
                deleteAllPatientDataModal: false,
                selecting: false,
            });
            this.props.dispatch(update_list(response.bracelets));
        }).catch((error) => {
            this.props.dispatch(showSnack('No se han podido eliminar los datos, prueba de nuevo en unos minutos'), 'warning');
            this.setState({
                sendingData: false,
                deleteAllPatientDataModal: false,
                selecting: false,
            });
        });
    };

    renderContent() {
        const braceletList = this.props.braceletList ? this.props.braceletList : [];
        let bracelets = [];
        let braceletsWithPatient = 0;
        let counter = 0;
        braceletList.forEach((bracelet) => {
            if (bracelet.patient) {
                braceletsWithPatient++;
            }
            counter++;
            bracelets.push(
                <BraceletCard
                    key={counter}
                    bracelet={bracelet}
                    selecting={this.state.selecting}
                    checked={this.state.selectedBracelets.indexOf(bracelet.uuid) !== -1}
                    onChangeCheck={this.handleToggle(bracelet.uuid)}
                />
            );
        });

        // If there is more than one bracelet with patient assigned, then set a flag to allow render of multiple select button
        if (braceletsWithPatient > 1 && !this.state.selectableBracelets) {
            this.setState({
                selectableBracelets: true
            });
        }

        return (
            <div className={bracelets.length > 1 ? importedStyles.content : importedStyles.contentSingle}>
                {this.state.selecting &&
                <div className={importedStyles.multipleSelectionControls}>
                    <div className={importedStyles.multipleSelectionInfo}>
                        <IconButton className={importedStyles.multipleSelectionInfoClose}
                                    onClick={() => this.setState({selecting: false, selectedBracelets: []})}>
                            <Close/>
                        </IconButton>
                        <Typography variant={"subtitle2"}>
                            Elementos seleccionados: {this.state.selectedBracelets.length}
                        </Typography>
                    </div>

                    {this.state.selectedBracelets && this.state.selectedBracelets.length > 0 &&
                    <Button variant={"contained"} color={"secondary"} onClick={this.handleDeleteAllPatientDataModal}>
                        Borrar datos
                    </Button>
                    }
                </div>
                }

                <ul>
                 {/*   <li>
                        <a href={'https://tienda.mipulsid.com/products/pulsid-original?utm_source=landing'} target={'_blank'} style={{textDecoration: 'none'}}>
                            <Button variant={"raised"} size={"large"}
                                    //onClick={this.handleAuthModal}
                                    className={importedStyles.getNewPulsIdButton}>
                                <ShopIcon style={{marginRight: '0.5rem'}}/> Comprar nueva PulsID
                            </Button>
                        </a>
                    </li>*/}
                    {bracelets}
                </ul>
            </div>
        );
    }

    handleNewBracelet(newBraceletUuid) {
        this.props.dispatch(add_bracelet({
            assigned: 1,
            patientData: null,
            size: 'S',
            color: null,
            uuid: newBraceletUuid,
        }));
    }


    render() {

        let content = '';
        if (this.state.sendingData) {
            content = <div className={importedStyles.chargingContent}>
                <CircularProgress
                    size={65}
                    className={importedStyles.chargingCircle}
                />
            </div>;
        } else if (!this.state.error) {
            content = this.renderContent();
        } else {
            content =
                <div className={importedStyles.noContent}>
                    <p className={importedStyles.errorMessage}> Ha ocurrido un error al cargar los datos</p>
                </div>;
        }
        if (this.props.user) {
            return (
                <div className={importedStyles.list}>
                    {content}
                    {/*{(this.state.selectableBracelets  && !this.state.selecting) &&
                    <Button size={"small"}
                            onClick={() => this.setState({selecting: true})}
                            className={importedStyles.multipleSelectionButton}
                    >
                        <CheckBoxIcon style={{marginRight: 5}}/>
                        Selección múltiple
                    </Button>
                    }*/}
                    {/* <Button color="primary" aria-label="Add" className={importedStyles.buttonNew}
                            onClick={this.handleAuthModal}>
                        <AddIcon style={{marginRight: 5}}/>
                        Comprar nueva pulsera
                    </Button>*/}

                    <Button
                        variant="contained"
                        aria-label="activa tu pulsera"
                        size={"large"}
                        className={importedStyles.floatingActiveBraceletButton}
                        onClick={() => history.push('/clave')}
                    >
                        <ActiveIcon className={importedStyles.activeIcon}/>
                        Activar nueva pulsera
                    </Button>


                    <AuthModals open={this.props.showBuyModal} newBracelet={true}
                                handleModal={() => this.handleAuthModal()} func_success={this.handleNewBracelet}/>
                    <PasswordChangeModal open={this.state.openChangePasswordModal}
                                         handleModal={this.handleChangePasswordModal}/>
                    <PatientModal/>
                    <EmergencyStepsModal/>
                    <Dialog
                        open={this.state.paymentSuccessAndShareModal}
                        onClose={() => this.setState({paymentSuccessAndShareModal: false})}>
                        <DialogTitle>
                            Compra confirmada
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant={'subtitle1'}>
                                    ¡Genial, pronto recibirás tu pulsera!
                                    <br/><br/>
                                    Si quieres, mientras esperas puedes compartirlo con tus amigos, ¡Es más rápido que
                                    hacerte un café!
                                    <br/>
                                </Typography>
                                -- Con amor, el equipo de PulsID
                            </DialogContentText>
                            <div id={'shareButtonsBox'} className={importedStyles.shareButtonsBox}>
                                <IconButton>
                                    <FacebookShareButton
                                        className={importedStyles.shareButton}
                                        url={'https://www.mipulsid.com'}
                                        quote={'¡Ahora me siento más seguro gracias a mi PulsID! Consigue la tuya aquí:'}
                                        hashtag={'#miPulsID'}
                                    >
                                        <FacebookIcon size={32} round/>
                                    </FacebookShareButton>
                                </IconButton>
                                <IconButton>
                                    <TwitterShareButton
                                        url={'https://www.mipulsid.com'}
                                        className={importedStyles.shareButton}
                                        title={'¡Ahora me siento más seguro gracias a mi PulsID! Consigue la tuya aquí: '}
                                        //via={'@seviSl'}
                                        hashtags={['miPulsID', 'MeSientoSeguro', 'SeviSystems']}
                                    >
                                        <TwitterIcon size={32} round/>
                                    </TwitterShareButton>
                                </IconButton>
                                <IconButton>
                                    <WhatsappShareButton
                                        url={'https://www.mipulsid.com'}
                                        className={importedStyles.shareButton}
                                        title={'¡Ahora me siento más seguro gracias a mi PulsID! Consigue la tuya aquí: '}
                                        separator={' '}
                                    >
                                        <WhatsappIcon size={32} round/>
                                    </WhatsappShareButton>
                                </IconButton>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({paymentSuccessAndShareModal: false})} color="primary">
                                No, gracias
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Delete all patient data confirmation MODAL */}
                    <Dialog
                        open={this.state.deleteAllPatientDataModal}
                        onClose={this.handleDeleteAllPatientDataModal}
                        disableBackdropClick
                    >
                        <DialogTitle>
                            <IconButton onClick={this.handleDeleteAllPatientDataModal}
                                        className={importedStyles.closeModalIcon}>
                                <CloseIcon/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Si eliminas los datos de estas pulseras no podrás recuperarlos más tarde.
                                ¿Deseas continuar con el borrado?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeleteAllPatientDataModal} color="secondary">
                                Cancelar
                            </Button>
                            <Button onClick={this.handleErasePatientData} color="primary" variant={"contained"}
                                    disabled={this.state.sendingData}>
                                Borrar {this.state.sendingData &&
                            <CircularProgress size={30} className={importedStyles.sendingDataAnimation}/>}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Navbar path={window.location.pathname}/>
                    {this.renderRatingsSocialsNetworks()}
                </div>
            );
        } else {
            return (<div/>);
        }
    }
}

const mapStateToProps = ({authReducer, braceletListReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        braceletList: braceletListReducer.braceletList,
        showBuyModal: authReducer.showBuyModal
    });
};
export default connect(mapStateToProps)(BraceletList);