import {
    PATIENT_UPDATE_CONTACTS_DATA,
    PATIENT_UPDATE_BASIC_DATA,
    PATIENT_UPDATE_DOCUMENTS_DATA,
    PATIENT_UPDATE_MEDIC_DATA,
    PATIENT_CLEAN_DATA,
    PATIENT_UPDATE_BRACELET_UUID,
    PATIENT_UPDATE_EDITTING,
    PATIENT_PREVIOUS_STEP,
    PATIENT_NEXT_STEP,
    PATIENT_HANDLE_MODAL,
    PATIENT_SENDING_DATA,
    PATIENT_HANDLE_EMERGENCY_STEPS_MODAL,
    PATIENT_UPDATE_EMERGENCY_STEPS,
    PATIENT_CLEAN_EMERGENCY_STEPS,
    CLOSE_ASSIGN_MODALS
} from "../../constants/action-types";

const initialState = {
    patientUuid: '',
    activeStep: 0,
    editing: false,
    braceletUuid: '',
    modalOpened: false,
    name: '',
    surname: '',
    bornDate: new Date(),
    patientPhoto: null,
    relationship: '',
    allergies: [],
    medicines: [],
    diagnosis: [],
    observations: '',
    address: {
        city: '',
        country: '',
        zipCode: '',
        region: '',
        address: '',
    },
    documents: [],
    emergencyContacts: [],
    sendingData: false,
    emergencySteps: [],
    emergencyStepsModalOpened: false,
};

const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case PATIENT_UPDATE_BASIC_DATA:
            return {
                ...state,
                name: action.name,
                surname: action.surname,
                bornDate: action.bornDate,
                patientPhoto: action.patientPhoto,
                address: action.address
            };
        case PATIENT_UPDATE_MEDIC_DATA:
            return {
                ...state,
                diagnosis: action.diagnosis,
                medicines: action.medicines,
                allergies: action.allergies,
                observations: action.observations
            };
        case PATIENT_UPDATE_EMERGENCY_STEPS:
            return {
                ...state,
                emergencySteps: action.emergencySteps,
            };
        case PATIENT_UPDATE_DOCUMENTS_DATA:
            return {...state, documents: action.documents};
        case PATIENT_UPDATE_CONTACTS_DATA:
            return {...state, emergencyContacts: action.contacts};
        case PATIENT_UPDATE_BRACELET_UUID:
            return {...state, braceletUuid: action.uuid};
        case PATIENT_HANDLE_MODAL:
            return {...state, modalOpened: !state.modalOpened, activeStep: 0};
        case PATIENT_HANDLE_EMERGENCY_STEPS_MODAL:
            return {...state, emergencyStepsModalOpened: !state.emergencyStepsModalOpened};
        case PATIENT_UPDATE_EDITTING:
            return {...state, editing: action.bool};
        case PATIENT_PREVIOUS_STEP:
            return {...state, activeStep: state.activeStep - 1};
        case PATIENT_NEXT_STEP:
            return {...state, activeStep: state.activeStep + 1};
        case PATIENT_CLEAN_DATA:
            const newState = {...initialState};
            newState.name = "";
            newState.emergencyContacts = [];
            newState.allergies = [];
            newState.medicines = [];
            newState.diagnosis = [];
            newState.observations = '';
            state = newState;
            return newState;
        case PATIENT_CLEAN_EMERGENCY_STEPS:
            return {...state, emergencySteps: []};
        case PATIENT_SENDING_DATA:
            return {...state, sendingData: action.sendingData};
        case CLOSE_ASSIGN_MODALS:
            return {...state, emergencyStepsModalOpened: false, modalOpened: false};
        default:
            return state;
    }
};


export default patientReducer;