import React from "react";
import importedStyles from "./allTutorials.module.sass";
import ResponsiveEmbed from 'react-responsive-embed';
import {Fab, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ShopIcon from "@material-ui/icons/Store";
import history from "../../helpers/history";
import Navbar from "../../components/Navbar/Navbar";
import TidioChat from "../../components/TidioChat/TidioChat";
import Card from "@material-ui/core/Card";

class allTutorials extends React.Component {
    render() {
        return (
            <Grid container className={importedStyles.container}>

                    <Navbar path={window.location.pathname} hidden={false}
                            style={{backgroundColor: '#4FA9B3 !important'}}/>

                <Grid item xs={12} md={6} className={importedStyles.gridItem}>
                    <Typography variant={'h3'} className={importedStyles.videoTitle}>¿Qué es PulsID?</Typography>
                    <div className={importedStyles.embedBox}>
                        <ResponsiveEmbed src='https://www.youtube.com/embed/95OAT9NHxyI?loop=1'
                                         allowFullScreen autoPlay ratio='16:9'/>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className={importedStyles.ctaContainer}>
                        <Typography variant={'h3'} className={importedStyles.ctaTitle}>Compra tu PulsID</Typography>
                        <Fab variant={'extended'} size={'large'} className={importedStyles.ctaButton} href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'} target={'_blank'}>
                            Tienda PulsID <ShopIcon style={{marginLeft: 20}}/>
                        </Fab>
                </Grid>

                <Grid item xs={12} md={6} className={importedStyles.gridItem}>
                    <Typography variant={'h3'} className={importedStyles.videoTitle}>CONFIGURA TU PULSERA</Typography>
                    <div className={importedStyles.embedBox}>
                        <ResponsiveEmbed src='https://www.youtube.com/embed/Zpz7v3kVhZY?loop=1'
                                         allowFullScreen ratio='16:9'/>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className={importedStyles.gridItem}>
                    <Typography variant={'h3'} className={importedStyles.videoTitle}>VER Y EDITAR TUS DATOS</Typography>
                    <div className={importedStyles.embedBox}>
                        <ResponsiveEmbed src='https://www.youtube.com/embed/zzIjM4khmcw?loop=1'
                                         allowFullScreen ratio='16:9'/>
                    </div>
                </Grid>
                <Grid item xs={12} md={8} className={importedStyles.gridItem}>
                    <Typography variant={'h3'} className={importedStyles.videoTitle}>TUTORIAL COMPLETO</Typography>
                    <div className={importedStyles.embedBox}>
                        <ResponsiveEmbed src='https://www.youtube.com/embed/ysIb7KW5IHY?loop=1' allowFullScreen ratio='16:9'/>
                    </div>
                </Grid>
                <TidioChat/>
            </Grid>
        )
    }
}

export default allTutorials;