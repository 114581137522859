import React from 'react';
import importedStyles from "./Navbar.module.sass";
import history from '../../helpers/history'
import LoginModal from '../../modules/Modals/LoginModal/LoginModal';
import {connect} from "react-redux";
import {logout, show_buy_modal} from "../../modules/Modals/ModalsActions";
import MenuIcon from '@material-ui/icons/Menu';
//import ListIcon from '@material-ui/icons/ViewList';
import ListIcon from '@material-ui/icons/VerticalSplit';
import ExitIcon from '@material-ui/icons/ExitToApp';
import UserIcon from '@material-ui/icons/PermIdentity';
import ActivateIcon from '@material-ui/icons/PlayArrow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import List from "@material-ui/core/es/List";
import ListItem from "@material-ui/core/es/ListItem";
import ListItemIcon from "@material-ui/core/es/ListItemIcon";
import ListItemText from "@material-ui/core/es/ListItemText";
import Divider from "@material-ui/core/es/Divider";
import IconButton from "@material-ui/core/es/IconButton";
import ListSubheader from "@material-ui/core/es/ListSubheader";
import SwipeableDrawer from "@material-ui/core/es/SwipeableDrawer";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const jwt = require('jsonwebtoken');


class Navbar extends React.Component {

    state = {
        open: false,
        logoutModal: false,
        hidden: false,
        openDrawer: false,
    };

    componentDidMount() {

        let sesionToken = localStorage.getItem('AuthToken');
        jwt.verify(sesionToken, 'secret', (err, decoded) => {
            if (err) {
                if (err.name === 'TokenExpiredError') {
                    this.props.dispatch(logout());
                    localStorage.removeItem('AuthToken');
                    history.push('/');
                }

            }
        });

        // When this component mounts, begin listening for scroll changes
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        // If this component is unmounted, stop listening
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {

        const currentScrollTop = window.scrollY;
        // Set the state of hidden depending on scroll position
        // We only change the state if it needs to be changed
        if (currentScrollTop > 15) {
            this.setState({hidden: true});
        } else {
            this.setState({hidden: false});
        }
    }

    constructor(props) {
        super(props);
        this.handleModal = this.handleModal.bind(this);
        this.handleModalLogout = this.handleModalLogout.bind(this);
        this.logout = this.logout.bind(this);
        // Bind the function to this component, so it has access to this.state
        this.handleScroll = this.handleScroll.bind(this);
    }


    handleModal = () => {
        this.setState({open: !this.state.open});
    };

    handleModalLogout() {
        this.setState({logoutModal: !this.state.logoutModal});
    };

    logout() {
        this.handleModalLogout();
        this.props.dispatch(logout());
        history.push('/');
    }

    getAction() {
        let buttons = (
            <div className={importedStyles.actionsContent}>
                {/*<Button size={"medium"} variant={'text'} onClick={()=>this.props.dispatch(show_buy_modal(true))} className={importedStyles.actionButtonGetIt}>Consíguela</Button>*/}
                <IconButton
                    aria-label="Open drawer"
                    onClick={this.toggleDrawer(true)}
                    className={importedStyles.menuButton}
                >
                    <MenuIcon fontSize={"large"}/>
                </IconButton>
                <a href="https://mipulsid.com/codigo" style={{textDecoration: "none"}}>
                    <Button size={"large"} variant={"outlined"}
                            className={importedStyles.actionButtonOutlined}>Activar</Button>
                </a>

            </div>
        );

        if (this.props.logged && this.props.user && this.props.user.uuid) {
            buttons = (
                <div className={importedStyles.actionsContent}>
                    {/*<Button size={"medium"} variant={"text"} onClick={()=> history.push('/clave')} className={importedStyles.activeBraceletButton}>Activar</Button>*/}
                    <IconButton
                        aria-label="Open drawer"
                        onClick={this.toggleDrawer(true)}
                        className={importedStyles.menuButton}
                    >
                        <MenuIcon fontSize={"large"}/>
                    </IconButton>
                    <Button size={"medium"} variant={"outlined"} onClick={() => history.push('/list')}
                            className={importedStyles.actionButtonOutlined}>Mis pulseras</Button>
                    <Button size={"medium"} onClick={this.handleModalLogout} className={importedStyles.actionButton}>Cerrar
                        sesión</Button>
                </div>
            );
        }
        return buttons;
    }

    getButtons() {

        if (this.props.path === '/') {
            return (
                <div className={importedStyles.buttonsContent}>
                    <AnchorLink id={'hiddenScrollToTopLink'} offset={() => 0} href='#headSection'
                                className={importedStyles.hiddenScrollToTopLink}>Scroll to top</AnchorLink>
                    <AnchorLink offset={() => 0} href='#protect'
                                className={importedStyles.landingButton}>Protegelos</AnchorLink>
                    <a href={'/nuestraHistoria'} className={importedStyles.landingButton}>Historia</a>
                    <a href={'/compromisoEco'} className={importedStyles.landingButton}>Eco</a>
                    <a href={'/tutoriales'} className={importedStyles.landingButton}>Tutoriales</a>
                    <a href={'/FAQ'} className={importedStyles.landingButton}>Preguntas frecuentes</a>
                    {/*<span onClick={()=>this.props.dispatch(show_buy_modal(true))} className={importedStyles.landingButton}>Consíguela ya</span>*/}
                </div>);
        } else if (this.props.path === '/nuestraHistoria' || this.props.path === '/compromisoEco' || this.props.path === '/FAQ' || this.props.path === '/tutoriales') {
            return (
                <div className={importedStyles.buttonsContent}>
                    <a href={'/nuestraHistoria'} className={importedStyles.landingButton}>Historia</a>
                    <a href={'/compromisoEco'} className={importedStyles.landingButton}>Eco</a>
                    <a href={'/tutoriales'} className={importedStyles.landingButton}>Tutoriales</a>
                    <a href={'/FAQ'} className={importedStyles.landingButton}>Preguntas frecuentes</a>
                    {/*<span onClick={()=>this.props.dispatch(show_buy_modal(true))} className={importedStyles.landingButton}>Consíguela ya</span>*/}
                </div>);
        } else if (this.props.path === '/tienda') {
            return (
                <div className={importedStyles.buttonsContent}>
                    <AnchorLink id={'hiddenScrollToTopLink'} offset={() => 0} href='#headSection'
                                className={importedStyles.hiddenScrollToTopLink}>Scroll to top</AnchorLink>
                    <AnchorLink offset={() => 0} href='#protect'
                                className={importedStyles.landingButton}>Protégelos
                    </AnchorLink>
                    <AnchorLink offset={() => 0} href='#history'
                                className={importedStyles.landingButton}>Historia</AnchorLink>
                    <a href={'/tutoriales'} className={importedStyles.landingButton}>Tutoriales</a>
                    <a href={'/FAQ'} className={importedStyles.landingButton}>Preguntas frecuentes</a>
                    {/*<span onClick={()=>this.props.dispatch(show_buy_modal(true))} className={importedStyles.landingButton}>Consíguela ya</span>*/}
                </div>);
        } else {
            return (<div/>);
        }
    }

    handleIconClick = () => {
        let actualRoute = this.props.path;
        if (actualRoute === '/' || actualRoute === '/tienda') {
            document.getElementById("hiddenScrollToTopLink").click();
        } else {
            history.push('/');
        }
    };

    toggleDrawer = (boolean) => () => {
        this.setState({openDrawer: boolean});
    };

    smoothScrollTo = (section, offset) => () => {
        window.scroll({
            top: document.getElementById(section).offsetTop - (offset ? offset : 0),
            behavior: 'smooth'
        });
    };

    handleBuyModal = () => {
        this.props.dispatch(show_buy_modal(!this.props.showBuyModal));

        if (this.props.showBuyModal) {
            ReactPixel.trackCustom('buyModalOpen', {description: 'El usuario ha empezado el flujo de compra'});
            ReactGA.modalview('/buyModalOpened');
            ReactGA.ga('send', 'event', 'click', 'buyModalOpen');
        }
    };

    renderDrawerContent = () => {
        let actualRoute = this.props.path;
        let mainActions = null;
        if (this.props.logged) {
            mainActions = (
                <List>
                    <ListItem button key={'activar'} onClick={() => history.push('/list')}>
                        <ListItemIcon> <ListIcon/> </ListItemIcon>
                        <ListItemText primary={'Mis pulseras'}/>
                    </ListItem>
                    <ListItem button key={'activar'} onClick={this.handleModalLogout}>
                        <ListItemIcon> <ExitIcon/> </ListItemIcon>
                        <ListItemText primary={'Cerrar sesión'}/>
                    </ListItem>
                </List>
            )
        } else {
            mainActions = (
                <List>
                    <ListItem button key={'activar'} onClick={() => history.push('/codigo')}>
                        <ListItemIcon> <ActivateIcon/> </ListItemIcon>
                        <ListItemText primary={'Activar pulsera'}/>
                    </ListItem>
                    <ListItem button key={'login'} onClick={this.handleModal}>
                        <ListItemIcon> <UserIcon/> </ListItemIcon>
                        <ListItemText primary={'Iniciar Sesión'}/>
                    </ListItem>
                </List>
            );
        }

        if (actualRoute === '/') {
            return (
                <React.Fragment>
                    {mainActions}
                    <Divider/>
                    <List subheader={<ListSubheader component="div">Secciones</ListSubheader>}>
                        <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'}
                           target={'_blank'} style={{textDecoration: 'none'}}>
                            <ListItem
                                className={importedStyles.nestedList}
                                button
                                key={'buy'}
                                //onClick={this.handleBuyModal}
                            >
                                <ListItemText primary={'Compra la tuya'}/>
                            </ListItem>
                        </a>
                        <ListItem className={importedStyles.nestedList} button
                                  onClick={this.smoothScrollTo('protect', 100)} key={'protect'}>
                            <ListItemText primary={'Protégelos'}/>
                        </ListItem>
                        <ListItem button className={importedStyles.nestedList}
                                  onClick={() => history.push('/nuestraHistoria')} key={'historia'}>Nuestra
                            historia</ListItem>
                        <ListItem button className={importedStyles.nestedList}
                                  onClick={() => history.push('/compromisoEco')} key={'eco'}>Compromiso eco</ListItem>

                        <ListItem className={importedStyles.nestedList} button
                                  onClick={() => history.push('/tutoriales')} key={'tutos'}>
                            <ListItemText primary={'Tutoriales'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} button onClick={() => history.push('/FAQ')}
                                  key={'faq'}>
                            <ListItemText primary={'Preguntas frecuentes'}/>
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        } else if (actualRoute === '/nuestraHistoria' || actualRoute === '/compromisoEco') {
            return (
                <React.Fragment>
                    {mainActions}
                    <Divider/>
                    <List subheader={<ListSubheader component="div">Secciones</ListSubheader>}>
                        <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'}
                           target={'_blank'} style={{textDecoration: 'none'}}>
                            <ListItem
                                className={importedStyles.nestedList}
                                button
                                key={'buy'}
                                //onClick={this.handleBuyModal}
                            >
                                <ListItemText primary={'Compra la tuya'}/>
                            </ListItem>
                        </a>
                        <ListItem button className={importedStyles.nestedList}
                                  onClick={() => history.push('/nuestraHistoria')} key={'historia'}>Nuestra
                            historia</ListItem>
                        <ListItem button className={importedStyles.nestedList}
                                  onClick={() => history.push('/compromisoEco')} key={'eco'}>Compromiso eco</ListItem>

                        <ListItem className={importedStyles.nestedList} button
                                  onClick={() => history.push('/tutoriales')} key={'tutos'}>
                            <ListItemText primary={'Tutoriales'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} button onClick={() => history.push('/FAQ')}
                                  key={'faq'}>
                            <ListItemText primary={'Preguntas frecuentes'}/>
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        } else if (this.props.path === '/tienda') {
            return (
                <React.Fragment>
                    {mainActions}
                    <Divider/>
                    <List subheader={<ListSubheader component="div">Secciones</ListSubheader>}>
                        <a href={'https://articulo.mercadolibre.com.ar/MLA-870335476-pulsid-pulsera-historia-clinica-en-qr-documento-y-avisos-web-_JM#position=2&type=item&tracking_id=e31c2e56-a2cb-4131-b6f8-319b0a26b6a3'}
                           target={'_blank'} style={{textDecoration: 'none'}}>
                            <ListItem
                                className={importedStyles.nestedList}
                                button
                                key={'buy'}
                                //onClick={this.handleBuyModal}
                            >
                                <ListItemText primary={'Compra la tuya'}/>
                            </ListItem>
                        </a>
                        <ListItem className={importedStyles.nestedList} button
                                  onClick={this.smoothScrollTo('protect', 100)} key={'protect:Link'}>
                            <ListItemText primary={'Protégelos'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} button
                                  onClick={this.smoothScrollTo('history', 100)} key={'history:Link'}>
                            <ListItemText primary={'Historia'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} button
                                  onClick={() => history.push('/tutoriales')} key={'tutos'}>
                            <ListItemText primary={'Tutoriales'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} button onClick={() => history.push('/FAQ')}
                                  key={'faq'}>
                            <ListItemText primary={'Preguntas frecuentes'}/>
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {mainActions}
                    <List subheader={<ListSubheader component="div">Secciones</ListSubheader>}>
                        <ListItem className={importedStyles.nestedList} button onClick={() => history.push('/')}
                                  key={'landing'}>
                            <ListItemText primary={'Página inicio'}/>
                        </ListItem>
                        <ListItem className={importedStyles.nestedList} button onClick={() => history.push('/FAQ')}
                                  key={'faq'}>
                            <ListItemText primary={'Preguntas frecuentes'}/>
                        </ListItem>
                    </List>
                </React.Fragment>
            );
        }
    };

    renderOfferBanner = () => {
        let actualRoute = this.props.path;
        if (actualRoute === '/') {
            return (
                <div className={importedStyles.offerBanner}>
                    Oferta especial de lanzamiento: antes 19.99€ ahora sólo 9.99€!
                </div>
            )
        }
    };

    render() {
        let backColor = '#4fa9b3cf';

        if (this.props.hidden && !this.state.hidden) {
            backColor = 'transparent';
        }
        if (/Edge/.test(navigator.userAgent)) {
            backColor = '#4FA9B3';
        }


        return (
            <div>
                <SwipeableDrawer anchor="right" open={this.state.openDrawer} onClose={this.toggleDrawer(false)}
                                 onOpen={this.toggleDrawer(true)}>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer(false)}
                        onKeyDown={this.toggleDrawer(false)}
                    >
                        {this.renderDrawerContent()}
                    </div>
                </SwipeableDrawer>
                <div style={{backgroundColor: backColor}} className={importedStyles.navbar}>
                    <img
                        className={importedStyles.logoImg}
                        src={require('../../img/logo.png')}
                        alt={'PulsID'}
                        onClick={this.handleIconClick}
                    />
                    <div className={importedStyles.leftIcons}>
                        <div className={importedStyles.spanishProductIcon}>
                            <img style={{width: 25}} src={require('../../img/icons/spanishFlag.png')}
                                 alt={'bandera española'}/>
                            <span>Modelo realizado</span>
                            <span>para latinoamérica</span>
                        </div>
                        <a href="https://www.facebook.com/mipulsid.arg">
                            <img style={{width: 35, paddingLeft: "1rem"}} src={require('../../img/facebook.png')}
                                 alt={'Facebook'}/>
                        </a>

                        <a href="https://www.instagram.com/mipulsid_arg/">
                            <img style={{width: 27, paddingLeft: "1rem"}} src={require('../../img/instagram.png')}
                                 alt={'Instagram'}/>
                        </a>
                    </div>


                    <div className={importedStyles.buttonsContainer}>
                        {this.getButtons()}
                        {this.getAction()}
                    </div>
                </div>
                <LoginModal
                    open={this.state.open}
                    handleModal={this.handleModal}
                />
                {/* LOGOUT CONFIRMATION MODAL */}
                <Dialog
                    className={importedStyles.logoutModal}
                    open={this.state.logoutModal}
                    onClose={this.handleModalLogout}
                >
                    <DialogTitle id="alert-dialog-title">{"Cerrar sesión"}</DialogTitle>
                    <DialogContent
                        className={importedStyles.contentModal}
                    >
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que desea cerrar sesión?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleModalLogout} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={() => {
                            localStorage.removeItem('AuthToken');
                            this.logout();
                        }} variant="contained" color="secondary" autoFocus>
                            Cerrar sesión
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


}

const mapStateToProps = ({authReducer}) => {
    return ({
        user: authReducer.user,
        logged: authReducer.logged,
        showBuyModal: authReducer.showBuyModal
    });
};
export default connect(mapStateToProps)((Navbar));